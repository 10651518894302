import React, { Component, Fragment } from 'react';

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.itemNumberRef = React.createRef();
    this.itemNameRef = React.createRef();
    this.state = { itemName: '' };
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.itemNumberRef.current.value = '';
      this.itemNameRef.current.value = '';
      this.itemNameRef.current.disabled = false;
    }
  }

  renderItems() {
    if (!this.props.items || !this.props.items?.length) {
      return null;
    }

    return this.props.items?.map(item => {
      return (
        <tr key={item.itemNumber}>
          <td>{item.itemNumber}</td>
          <td>{item.name}</td>
          <td>
            <i className='fa fa-minus' onClick={() => this.removeItem(item)}></i>
          </td>
        </tr>
      );
    });
  }

  updateItemName(e) {
    if (!e.target) {
      return;
    }
    this.setState({ itemName: e.target.value });
  }

  addItem() {
    if (this.props.editMode && this.props.items.length > 0) {
      return;
    }
    const items = this.props.items;
    let item = this.props.currentItem.item;
    if (this.props.currentItem.error || !item) {
      item = {
        itemNumber: this.itemNumberRef.current.value.trim(),
        name: this.itemNameRef.current.value
      };
    }

    if (items.filter(x => x.itemNumber === item.itemNumber).length > 0) {
      return;
    }
    items.push(item);
    this.itemNumberRef.current.value = '';
    this.itemNameRef.current.value = '';
    this.itemNameRef.current.disabled = false;
    this.props.setItems(items);
  }

  removeItem(item) {
    const index = this.props.items.indexOf(item);
    if (index < 0) {
      return;
    }
    const items = this.props.items;
    items.splice(index, 1);
    this.props.setItems(items);
  }

  async searchItemNumber(e) {
    if (e.target) {
      await this.props.search(e.target.value.trim());
      const name = this.props.currentItem.item ? this.props.currentItem.item.name : '';
      this.itemNameRef.current.value = name;
      this.itemNameRef.current.disabled = name;
      this.setState({ itemName: name });
    }
  }

  render() {
    return (
      <Fragment>
        <div className='form-row col-sm-12'>
          <h5>Item</h5>
        </div>
        <div className='form-row col-sm-8 d-flex align-items-end'>
          <div className='form-group col-sm-5 position-relative'>
            <label htmlFor='searchItemNumber'>Item number {!this.props.items.length && '*'}</label>
            <input
              type='text'
              className='form-control'
              id='searchItemNumber'
              minLength='1'
              ref={this.itemNumberRef}
              onBlur={this.searchItemNumber.bind(this)}
            ></input>
            <div>
              {!this.props.pending && this.props.currentItem.error && (
                <i
                  className='fa fa-exclamation-triangle'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='The item number was not found in GIM, if you still want to continue please enter the item number and name manually'
                  aria-hidden='true'
                ></i>
              )}
              {!this.props.pending && this.props.currentItem.item && (
                <i className='fa fa-check' data-toggle='tooltip' data-placement='top' aria-hidden='true'></i>
              )}
            </div>
            {this.props.pending && (
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}
          </div>
          <div className='form-group col-sm-5'>
            <label htmlFor='itemName'>Item name {!this.props.items.length && '*'}</label>
            <input
              type='text'
              className='form-control'
              id='itemName'
              ref={this.itemNameRef}
              onChange={this.updateItemName.bind(this)}
            ></input>
          </div>
          <div className='form-group'>
            <button
              type='button'
              className='btn btn-secondary'
              disabled={
                this.props.pending || !this.state.itemName || (this.props.editMode && this.props.items.length > 0)
              }
              title={this.props.editMode ? 'You cannot add items to an existing certificate' : 'Add your item'}
              onClick={this.addItem.bind(this)}
            >
              <i className='fa fa-plus'></i>
            </button>
          </div>
        </div>
        {this.props.items.length > 0 && (
          <div className='form-row col-sm-12'>
            <div className='col-sm-12'>
              <label htmlFor='itemsTable'>
                Selected items (Please note that every selected item creates a separate certificate.)
              </label>
              <table className='table table-sm table-striped table-hover col-sm-7' id='itemsTable'>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{this.renderItems(this.props.items)}</tbody>
              </table>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
