import React, { Component, Fragment } from 'react';

export default class MechanicalRangesDecimalInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      classes: 'form-control',
      selectedRangeId: null
    };
  }

  async componentDidMount() {
    let selectedRangeId = this.props.unitId;
    if (this.props.property.validationRanges.length === 1) {
      selectedRangeId = this.props.property.validationRanges[0].id;
    }

    this.setState({
      selectedRangeId: selectedRangeId
    });
    this.validate();
  }

  update = e => {
    e.preventDefault();
    if (!this.validate()) {
      return;
    }
    // convert to float
    const value = +e.target.value;

    this.setState({ inputValue: value });

    this.props.onChange(e.target.id, this.state.selectedRangeId, value);
  };

  setSelectedRange(event) {
    this.inputRef.current.value = null;
    this.setState({ selectedRangeId: event.target.value });
  }

  validate() {
    if (!this.inputRef.current || !this.inputRef.current.value) {
      return false;
    }
    if (!this.inputRef.current.checkValidity()) {
      this.setState({
        classes: 'form-control is-invalid'
      });
      return false;
    } else {
      this.setState({
        classes: 'form-control'
      });
      return true;
    }
  }

  renderOption(range) {
    if (!range) {
      return null;
    }
    return (
      <option key={range.id} value={range.id}>
        {range.unit}
      </option>
    );
  }

  renderInput(selectedRangeId) {
    let minValue = null;
    let maxValue = null;
    let labelText = this.props.property.propertyName;

    const selectedRanges = this.props.property.validationRanges.filter(
      range => range.id === this.state.selectedRangeId
    );
    if (selectedRanges.length) {
      minValue = selectedRanges[0].minValue;
      maxValue = selectedRanges[0].maxValue;
      labelText = `${this.props.property.propertyName} min: ${minValue}, max: ${maxValue}`;
    }

    return (
      <div className='form-group col-sm-4' style={{ paddingLeft: 1 }}>
        <label htmlFor={selectedRangeId}>
          {this.props.property.propertyName} {this.props.property.required ? '*' : ''}&nbsp;
        </label>
        <input
          type='number'
          step='any'
          className={this.state.classes}
          id={this.props.property.id}
          ref={this.inputRef}
          placeholder={labelText}
          min={minValue}
          max={maxValue}
          onChange={this.update.bind(this)}
          required={this.props.property.required}
          defaultValue={this.props.value}
          lang='sv'
        />
      </div>
    );
  }

  render() {
    let validationRanges = [];
    if (this.props.property && this.props.property.validationRanges) {
      validationRanges = this.props.property.validationRanges;
    }
    let defaultValue = this.props.unitId;
    if (validationRanges.length === 1) {
      defaultValue = validationRanges[0].id;
    }
    if (defaultValue === null || defaultValue === undefined || defaultValue === '') {
      defaultValue = '';
    }

    return (
      <Fragment>
        <div className='form-group col-sm-auto' style={{ paddingRight: 0 }}>
          <label htmlFor={'select-' + this.props.property.id}>{this.props.property.required ? '*' : ''}&nbsp;</label>
          <select
            defaultValue={defaultValue}
            id={'select-' + this.props.property.id}
            className='form-control'
            onChange={this.setSelectedRange.bind(this)}
            required={this.props.property.required}
          >
            <option disabled value=''>
              Unit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </option>
            {validationRanges.map(data => this.renderOption(data))}
          </select>
        </div>
        {this.renderInput(this.state.selectedRangeId)}
      </Fragment>
    );
  }
}
