import React, { useCallback, useEffect, useState } from 'react';

import WarningModal from '../Modals/WarningModal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFileClear } from 'store/uploads/actions.js';
import uploadFileAsync from 'store/uploads/uploadFile.js';

export function FileTransfer({ setFileData, fileName, entityId, uploadDocumentType, downloadDocumentType }) {
  const [fileState, setFileState] = useState({ file: undefined, fileName: '' });
  const [showUploadFailedModal, setShowUploadFailedModal] = useState(false);

  const dispatch = useDispatch();
  const uploadFile = useSelector(state => state.uploadFile);

  useEffect(() => {
    dispatch(uploadFileClear());
  }, [dispatch]);

  useEffect(() => {
    if (uploadFile.error) {
      setShowUploadFailedModal(true);
    }

    if (!uploadFile.pending && uploadFile.data) {
      setFileData(fileState.fileName, uploadFile.data);
    }
  }, [uploadFile, fileState, setFileData]);

  const onFileChange = useCallback(
    input => {
      let newFileName;
      let newFile;
      if (input.target.files?.length > 0) {
        newFileName = input.target.files[0].name;
        newFile = input.target.files[0];
      } else if (input.files?.length > 0) {
        newFileName = input.files[0].name;
        newFile = input.files[0];
      } else {
        return;
      }

      setFileState({ fileName: newFileName, file: newFile });

      dispatch(uploadFileAsync({ type: uploadDocumentType, file: newFile }));
    },
    [dispatch, uploadDocumentType]
  );

  const toggleUploadFailedModal = useCallback(() => {
    setShowUploadFailedModal(prevState => !prevState);
  }, []);

  return (
    <>
      <div className='form-group col-sm-8'>
        <label htmlFor='file'>Upload file *</label>
        <div className='input-group'>
          <div className='custom-file'>
            <input type='file' className='custom-file-input' id='file' onChange={onFileChange} required={!fileName} />
            <label id='file-upload-label' className='custom-file-label' htmlFor='file' aria-describedby='file'>
              {fileName || 'Choose file'}
              {uploadFile.pending && (
                <div className='spinner-border spinner-border-sm' role='status'>
                  <span className='sr-only'>Uploading file...</span>
                </div>
              )}
              {uploadFile.error && fileName && (
                <div
                  className='fa fa-exclamation-triangle'
                  role='status'
                  title='Unable to upload file, please try again'
                >
                  <span className='sr-only'>Unable to upload file, please try again</span>
                </div>
              )}
              {uploadFile.data && fileName && (
                <div className='fa fa-check' role='status' title='File uploaded'>
                  <span className='sr-only'>Unable to upload file, please try again</span>
                </div>
              )}
            </label>
          </div>
          {entityId && fileName && (
            <div className='input-group-append'>
              <a
                href={`/api/download/${downloadDocumentType}/${entityId}`}
                className='btn btn-outline-secondary'
                target='_blank'
                rel='noopener noreferrer'
              >
                View
              </a>
            </div>
          )}
        </div>
      </div>
      <UploadFailedModal
        showUploadFailedModal={showUploadFailedModal}
        uploadFile={uploadFile}
        toggleUploadFailedModal={toggleUploadFailedModal}
      />
    </>
  );
}

function UploadFailedModal({ showUploadFailedModal, uploadFile, toggleUploadFailedModal }) {
  if (!showUploadFailedModal) {
    return null;
  }
  const error = uploadFile?.error;
  const innerErrors = error?.innerErrors.map(x => x.innerErrors).flat();
  const body = (
    <>
      <div>Reason: {error?.message}</div>
      {innerErrors.map(innerError => {
        return <div key={innerError.message}>{innerError.message}</div>;
      })}
    </>
  );
  const header = 'Unable to upload file.';

  return <WarningModal show={true} toggle={toggleUploadFailedModal} header={header} body={body} />;
}
