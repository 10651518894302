import React, { useCallback } from 'react';
import { FileTransfer } from 'components/FileTransfer/FileTransfer';

export function EditQdocFileUpload({ uploadFile, setFileData, id, fileName }) {
  const onSetFileData = useCallback((fileName, fileId) => setFileData(fileName, fileId), [setFileData]);

  return (
    <>
      <div className='form-row col-sm-12'>
        <h5>File</h5>
      </div>
      <div className='form-row col-sm-12'>
        <FileTransfer
          uploadFile={uploadFile}
          setFileData={onSetFileData}
          fileName={fileName}
          entityId={id}
          uploadDocumentType='Qdoc'
          downloadDocumentType='Qdoc'
        />
      </div>
    </>
  );
}
