export const POST_SUPPLIER_PENDING = 'POST_SUPPLIER_PENDING';
export const POST_SUPPLIER_SUCCESS = 'POST_SUPPLIER_SUCCESS';
export const POST_SUPPLIER_ERROR = 'POST_SUPPLIER_ERROR';

export function postSupplierPending() {
  return {
    type: POST_SUPPLIER_PENDING
  };
}

export function postSupplierSuccess(data) {
  return {
    type: POST_SUPPLIER_SUCCESS,
    data: data
  };
}

export function postSupplierError(error) {
  return {
    type: POST_SUPPLIER_ERROR,
    error: error
  };
}
