export const POST_MANUFACTURER_PENDING = 'POST_MANUFACTURER_PENDING';
export const POST_MANUFACTURER_SUCCESS = 'POST_MANUFACTURER_SUCCESS';
export const POST_MANUFACTURER_ERROR = 'POST_MANUFACTURER_ERROR';

export function postManufacturerPending() {
  return {
    type: POST_MANUFACTURER_PENDING
  };
}

export function postManufacturerSuccess(data) {
  return {
    type: POST_MANUFACTURER_SUCCESS,
    data: data
  };
}

export function postManufacturerError(error) {
  return {
    type: POST_MANUFACTURER_ERROR,
    error: error
  };
}
