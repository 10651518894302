import React from 'react';
import { SearchAll } from '../Search';

export function Home() {
  return (
    <div className='container'>
      <div className='d-flex mt-3 justify-content-center'>
        <h3>Welcome to Alfa Laval&apos;s Certificate Database</h3>
      </div>
      <SearchAll useQdocs={true} useCerts={true} />
      <div className='d-flex justify-content-center mt-3'>
        <div className='col-sm-6 col-lg-5'>
          <h5>What can you find?</h5>
          <p>
            Certificates and MTR&apos;s (Material Test Report): search by heat number, item number, Alfa Laval ID and/or
            MTR ID.
          </p>
          <p>Q-docs: search by serial number and/or item number.</p>

          <h6>Need Installation Material as product model CAD files?</h6>
          <p className='small'>
            <a
              href='https://alfalaval.partcommunity.com/3d-cad-models/installation-material-alfa-laval?info=alfalaval%2Finstallation_material&cwid=5496'
              target='_blank'
              rel='noopener noreferrer'
            >
              Visit the Alfa Laval CAD portal here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
