import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import Download from '../Download/Download.jsx';
import { PaginatedResult } from './PaginatedResult.jsx';
import { useSelector } from 'react-redux';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';
import { useAuth } from 'auth/useAuth';

export function SearchQDocs({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchQDocs);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  if (!shouldRender) return null;

  return (
    <Accordion className='mt-4'>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey='0'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='btn btn-link'>Q-docs</div>
            {error && <div style={{ color: 'red' }}>{error.message}</div>}
            <div>{pending ? <i className='fas fa-circle-notch fa-spin' /> : data?.totalCount}</div>
          </div>
        </Accordion.Toggle>
        {data?.results?.length > 0 && (
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              {data.results.map(qdoc => (
                <Card key={qdoc.id} className={qdoc.invalid ? 'invalid-card' : undefined}>
                  <Card.Header>
                    <div className='row'>
                      <div className='col-lg-3 col-md-3'>
                        <b>Serial number</b>
                        <ul className='list-unstyled mb-md-0'>
                          {hasElevatedRole() ? (
                            <li>
                              <Link
                                className={qdoc.invalid ? 'invalid-certificate' : undefined}
                                to={'/qdoc/' + qdoc.id + '/details'}
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={stopPropagation}
                                title={qdoc.invalid ? 'The certificate is invalid' : 'Click to view details'}
                              >
                                {qdoc.serialNumber} {qdoc.invalid && <i className='fa fa-exclamation m-1' />}
                              </Link>
                            </li>
                          ) : (
                            <li>{qdoc.serialNumber}</li>
                          )}
                        </ul>
                      </div>
                      <div className='col-lg-3 col-md-3'>
                        <b>Item numbers</b>
                        <ul className='list-unstyled mb-md-0'>
                          <li>{qdoc.itemNumbers.join(', ')}</li>
                        </ul>
                      </div>
                      <div className='col-lg-2 col-md-2'>
                        <b>Product group</b>
                        <ul className='list-unstyled mb-md-0'>
                          <li>{qdoc.productGroup}</li>
                        </ul>
                      </div>
                      <div className='col-lg-2 col-md-2'>
                        <b>Product type</b>
                        <ul className='list-unstyled mb-md-0'>
                          <li>{qdoc.productType}</li>
                        </ul>
                      </div>
                      <div className='col-md-2'>
                        <b>Download</b>
                        <ul className='list-unstyled'>
                          <li>
                            <Download
                              fileName={qdoc.fileName}
                              identifier={qdoc.id}
                              documentType='qdoc'
                              alt={'Download QDoc file'}
                              onClick={stopPropagation}
                            />
                            {qdoc.invalid && <i className='fa fa-exclamation m-1 invalid-certificate' />}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Card.Header>
                </Card>
              ))}
              <br />
              <PaginatedResult totalCount={data?.totalCount} pageCount={pageCount} search={search} pending={pending} />
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
}
