import React, { useCallback, useMemo } from 'react';
import './SearchAll.css';
import { useAuth } from 'auth/useAuth';

export function SearchForm({
  useCerts,
  useQdocs,
  advancedSearch,
  searchInputForm,
  onAdvancedSearch,
  search,
  onSearchInputFormChange
}) {
  const { hasElevatedRole } = useAuth();

  const onToggleAdvancedSearch = useCallback(() => onAdvancedSearch(!advancedSearch), [
    onAdvancedSearch,
    advancedSearch
  ]);

  const placeholder = useMemo(() => {
    let placeHolder = hasElevatedRole()
      ? 'Heat, item, PO, lot or serial number, Alfa Laval or MTR ID...'
      : 'Heat, item, or serial number, Alfa Laval or MTR ID...';
    if (useCerts && !useQdocs) {
      placeHolder = hasElevatedRole()
        ? 'Heat, item, PO or lot number, Alfa Laval or MTR ID...'
        : 'Heat or item number, Alfa Laval or MTR ID...';
    }
    if (useQdocs && !useCerts) {
      placeHolder = 'Serial or item number...';
    }
    return placeHolder;
  }, [useCerts, useQdocs, hasElevatedRole]);

  const onUpdateSearchInputForm = useCallback(
    e => {
      const { id, value } = e.target;
      onSearchInputFormChange(prevState => ({ ...prevState, [id]: value }));
    },
    [onSearchInputFormChange]
  );

  const onSearch = useCallback(
    async e => {
      e.preventDefault();
      await search(1);
    },
    [search]
  );

  return (
    <form className='col-8' onSubmit={onSearch}>
      <div className='form-group'>
        <button type='button' className='btn btn-link' onClick={onToggleAdvancedSearch}>{`${
          advancedSearch ? 'Hide' : 'Show'
        } advanced search`}</button>
      </div>
      {!advancedSearch && (
        <div className='form-group'>
          <input
            id='keyword'
            type='text'
            className='form-control'
            placeholder={placeholder}
            onChange={onUpdateSearchInputForm}
            value={searchInputForm.keyword}
          />
        </div>
      )}
      {advancedSearch && (
        <div className='row row-cols-4'>
          <div className='col mb-2'>
            <label htmlFor='heatNumber'>Heat No.</label>
            <input
              id='heatNumber'
              type='text'
              className='form-control'
              onChange={onUpdateSearchInputForm}
              value={searchInputForm.heatNumber}
            />
          </div>
          <div className='col mb-2'>
            <label htmlFor='itemNumber'>Item No.</label>
            <input
              id='itemNumber'
              type='text'
              className='form-control'
              onChange={onUpdateSearchInputForm}
              value={searchInputForm.itemNumber}
            />
          </div>
          <div className='col mb-2'>
            <label htmlFor='alfaLavalId'>Alfa Laval ID</label>
            <input
              id='alfaLavalId'
              type='text'
              className='form-control'
              onChange={onUpdateSearchInputForm}
              value={searchInputForm.alfaLavalId}
            />
          </div>
          <div className='col mb-2'>
            <label htmlFor='mtrId'>MTR ID</label>
            <input
              id='mtrId'
              type='text'
              className='form-control'
              onChange={onUpdateSearchInputForm}
              value={searchInputForm.mtrId}
            />
          </div>
          {hasElevatedRole() && (
            <div className='col mb-2'>
              <label htmlFor='lotNumber'>Lot No.</label>
              <input
                id='lotNumber'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.lotNumber}
              />
            </div>
          )}
          {hasElevatedRole() && (
            <div className='col mb-2'>
              <label htmlFor='poNumber'>PO No.</label>
              <input
                id='poNumber'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.poNumber}
              />
            </div>
          )}
          {useQdocs && (
            <div className='col mb-2'>
              <label htmlFor='serialNumber'>Q-doc Serial No.</label>
              <input
                id='serialNumber'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.serialNumber}
              />
            </div>
          )}
        </div>
      )}
      <div className='form-group'>
        <button type='submit' className='btn btn-primary d-block ml-auto'>
          Search
        </button>
      </div>
    </form>
  );
}
