import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Unauthorized from '../Layout/Unauthoried.jsx';

import fetchManufacturersAsync from '../../store/manufacturers/fetchManufacturers';
import postManufacturerAsync from '../../store/editManufacturer/postManufacturer';

import { BaseWrite } from './BaseWrite.jsx';
import { useAuth } from 'auth/useAuth.js';

export function Manufacturer() {
  const manufacturers = useSelector(state => state.manufacturers);
  const editManufacturer = useSelector(state => state.editManufacturer);
  const { isAdmin } = useAuth();

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const fetchData = useCallback(async () => {
    await dispatch(fetchManufacturersAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (manufacturers?.data) {
      setItems(manufacturers.data);
      setAllItems(manufacturers.data);
    }
  }, [manufacturers]);

  const updateItems = useCallback(items => {
    setItems([...items]);
  }, []);

  const save = useCallback(
    async item => {
      await dispatch(postManufacturerAsync(item));
      await fetchData();
    },
    [dispatch, fetchData]
  );

  if (!isAdmin()) {
    return <Unauthorized />;
  }
  return (
    <BaseWrite
      name='Manufacturers'
      items={items}
      allItems={allItems}
      save={save}
      saveResult={editManufacturer}
      refreshData={fetchData}
      setItems={updateItems}
      isAdmin={isAdmin}
    />
  );
}
