import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditCertificateBase from '../CertificateBase/EditCertificateBase.jsx';
import CertificateSource from '../CertificateSource/CertificateSource.jsx';
import postMtrAsync from '../../store/editMtr/postMtr';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDocumentIds } from 'components/CertificateBase/useDocumentIds.js';
import fetchSurfacesAsync from 'store/surfaces/fetchSurfaces.js';
import { useApi } from 'api/useApi.js';
import { postEditMtrClear } from 'store/editMtr/actions.js';
import { useAuth } from 'auth/useAuth.js';

const TYPE = 'MTR';
const DESCRIPTION = 'MTR';

export function EditMtr() {
  const [certificateData, setCertificateData] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [pdfHtmlRendered, setPdfHtmlRendered] = useState(undefined);
  const [pendingSave, setPendingSave] = useState(undefined);
  const [pdfHtmlData, setPdfHtmlData] = useState(undefined);
  const [savedCertificate, setSavedCertificate] = useState(undefined);
  const [savedResponse, setSavedResponse] = useState(undefined);

  const { hasElevatedRole, isAsmeSupplier } = useAuth();

  const surfaces = useSelector(state => state.surfaces);
  const editMtr = useSelector(state => state.editMtr);

  const { getJson } = useApi();

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const clearState = useCallback(() => {
    setPendingSave(false);
    setPdfHtmlData(undefined);
    setPdfHtmlRendered(false);
    setSavedCertificate(undefined);
    setSavedResponse(undefined);
    dispatch(postEditMtrClear());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearState();
      dispatch(postEditMtrClear());
    };
  }, [dispatch, clearState]);

  const { generateItems } = useDocumentIds(TYPE);

  useEffect(() => {
    dispatch(fetchSurfacesAsync(TYPE));
  }, [dispatch]);

  const fetchBasicSupplierCertificatesAsync = useCallback(
    async heatId => {
      return await getJson(`supplier-certificate/basic-information/${encodeURIComponent(heatId)}`);
    },
    [getJson]
  );

  const loadCertificate = useCallback(
    async certId => {
      if (!certId) {
        return null;
      }

      return getJson(`mtr/${certId}`);
    },
    [getJson]
  );

  const resetUrl = useCallback(() => {
    history.replace('/edit-mtr/');
  }, [history]);

  useEffect(() => {
    if (editMtr?.pending || (!editMtr?.data && !editMtr.error)) return;

    if (editMtr?.data?.length) {
      const certId = editMtr.data[0].certificateId;
      const itemNumber = editMtr.data[0].itemNumber;
      const items = certificateData?.items?.filter(x => x.itemNumber === itemNumber);
      const item = items?.length ? items[0] : undefined;
      const documentId = editMtr.data[0].documentId;
      setCertificateData(undefined);
      history.replace('/edit-mtr/' + certId);
      setPendingSave(false);
      setPdfHtmlData(undefined);
      setPdfHtmlRendered(false);
      setSavedCertificate(editMtr);
      setSavedResponse({ id: certId, item: item, documentId: documentId });
    } else {
      setPendingSave(false);
      setPdfHtmlData(undefined);
      setPdfHtmlRendered(false);
      setSavedCertificate(editMtr);
    }
  }, [editMtr, certificateData, history]);

  const saveCertificate = useCallback(async () => {
    setPendingSave(true);

    const data = { ...certificateData };

    data.items.forEach(item => {
      var element = document.getElementById(item.documentId);
      item.html = element.innerHTML;
    }, this);

    dispatch(postMtrAsync(data));

    setCertificateData(data);
  }, [certificateData, dispatch]);

  const onSetCertificateData = useCallback(
    async data => {
      const dataToUpdate = data ? { ...data } : undefined;
      if (dataToUpdate && isAsmeSupplier()) {
        const res = await getJson('document/asme-certification/current');
        dataToUpdate.documentCertification = res;
      }
      setCertificateData(dataToUpdate);
    },
    [getJson, isAsmeSupplier]
  );

  const onPdfHtmlRendered = useCallback(() => {
    setPdfHtmlRendered(prevState => !prevState);
  }, []);

  const startRenderPdfHtml = useCallback((certificateData, selectedCertificates, selectedStandard) => {
    setPdfHtmlData({
      certificateData: certificateData,
      selectedCertificates: selectedCertificates,
      selectedStandard: selectedStandard
    });
  }, []);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  return (
    <>
      <EditCertificateBase
        save={saveCertificate}
        savedCertificate={savedCertificate}
        savedResponse={savedResponse}
        pendingSave={pendingSave}
        generateItemsWithDocumentIds={generateItems}
        setCertificateData={onSetCertificateData}
        certificateData={certificateData}
        loadCertificate={loadCertificate}
        type={TYPE}
        description={DESCRIPTION}
        id={id}
        renderPdfHtml={startRenderPdfHtml}
        pdfHtmlRendered={onPdfHtmlRendered}
        resetUrl={resetUrl}
        location={location}
        history={history}
        surfaces={surfaces}
        fetchBasicSupplierCertificatesAsync={fetchBasicSupplierCertificatesAsync}
        clearState={clearState}
        isAsmeSupplier={isAsmeSupplier}
      />
      {pdfHtmlData && (
        <div style={{ display: 'none' }}>
          <CertificateSource
            data={pdfHtmlData.certificateData}
            supplierCertificates={pdfHtmlData.selectedCertificates}
            standard={pdfHtmlData.selectedStandard}
            updateComplete={onPdfHtmlRendered}
            type={TYPE}
          />
        </div>
      )}
    </>
  );
}
