import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchForm } from './SearchForm';
import { SearchCertificates } from './SearchCertificatesResult';
import { useSearch } from './useSearch';
import { SearchQDocs } from './SearchQDocsResult';
import { SearchHeats } from './SearchHeatsResult';
import { SearchSupplierCertificates } from './SearchSupplierCertificatesResult';
import './SearchAll.css';

const QUERY = {
  HEAT_NUMBER: 'heatNumber',
  ITEM_NUMBER: 'itemNumber',
  ALFALAVAL_ID: 'alfaLavalId',
  MTR_ID: 'mtrId',
  LOT_NUMBER: 'lotNumber',
  PO_NUMBER: 'poNumber',
  SERIAL_NUMBER: 'serialNumber'
};

const initialSearchState = {
  keyword: '',
  heatNumber: '',
  itemNumber: '',
  alfaLavalId: '',
  mtrId: '',
  lotNumber: '',
  poNumber: '',
  serialNumber: ''
};

export function SearchAll({ useQdocs = false, useCerts = true }) {
  const [formData, setFormData] = useState(initialSearchState);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState(false);
  const { search: searchLoc, state } = useLocation();

  useEffect(() => {
    if (state?.refreshState) {
      setFormData(initialSearchState);
    }
  }, [state]);

  const { search, setSearchInitiated, searchInitiated, qdocs, certificates, heats, supplierCertificates } = useSearch({
    searchFormState: formData,
    advancedSearch,
    useCerts,
    useQdocs
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(searchLoc);
    const heatNumber = searchParams.get(QUERY.HEAT_NUMBER);
    const itemNumber = searchParams.get(QUERY.ITEM_NUMBER);
    const alfaLavalId = searchParams.get(QUERY.ALFALAVAL_ID);
    const mtrId = searchParams.get(QUERY.MTR_ID);
    const lotNumber = searchParams.get(QUERY.LOT_NUMBER);
    const poNumber = searchParams.get(QUERY.PO_NUMBER);
    const serialNumber = searchParams.get(QUERY.SERIAL_NUMBER);

    setFormData({
      ...formData,
      heatNumber: heatNumber || '',
      itemNumber: itemNumber || '',
      alfaLavalId: alfaLavalId || '',
      mtrId: mtrId || '',
      lotNumber: lotNumber || '',
      poNumber: poNumber || '',
      serialNumber: serialNumber || ''
    });

    if (heatNumber || itemNumber || alfaLavalId || mtrId || lotNumber || poNumber || serialNumber) {
      setAdvancedSearch(true);
      setQuerySearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLoc]);

  useEffect(() => {
    if (querySearch) {
      search(1);
    }
  }, [querySearch, search]);

  const shouldRenderCertificates = useMemo(() => {
    const stateCopy = { ...formData };
    if (advancedSearch) {
      delete stateCopy.keyword;
    }
    return searchInitiated && certificates.shouldSearch(stateCopy);
  }, [searchInitiated, certificates, formData, advancedSearch]);

  const shouldRenderHeats = useMemo(() => {
    const stateCopy = { ...formData };
    if (advancedSearch) {
      delete stateCopy.keyword;
    }
    return searchInitiated && heats.shouldSearch(stateCopy);
  }, [searchInitiated, heats, formData, advancedSearch]);

  const shouldRenderSupplierCertificates = useMemo(() => {
    const stateCopy = { ...formData };
    if (advancedSearch) {
      delete stateCopy.keyword;
    }
    return searchInitiated && supplierCertificates.shouldSearch(stateCopy);
  }, [searchInitiated, supplierCertificates, formData, advancedSearch]);

  const shouldRenderQdocs = useMemo(() => {
    const stateCopy = { ...formData };
    if (advancedSearch) {
      delete stateCopy.keyword;
    }
    return searchInitiated && qdocs.shouldSearch(stateCopy);
  }, [searchInitiated, qdocs, formData, advancedSearch]);

  const onAdvancedSearchChanged = useCallback(
    advancedSearch => {
      setAdvancedSearch(advancedSearch);
      setSearchInitiated(false);
    },
    [setSearchInitiated]
  );

  const onFormDataChanged = useCallback(
    formData => {
      setFormData(formData);
      setSearchInitiated(false);
    },
    [setSearchInitiated]
  );

  return (
    <>
      <div className='container d-flex justify-content-center'>
        <SearchForm
          useQdocs={useQdocs}
          useCerts={useCerts}
          advancedSearch={advancedSearch}
          searchInputForm={formData}
          onAdvancedSearch={onAdvancedSearchChanged}
          onSearchInputFormChange={onFormDataChanged}
          search={search}
        />
      </div>
      {searchInitiated &&
        !shouldRenderCertificates &&
        !shouldRenderHeats &&
        !shouldRenderSupplierCertificates &&
        !shouldRenderQdocs && (
          <div className='container d-flex justify-content-center'>
            <p>Invalid search input combination...</p>
          </div>
        )}
      {useCerts && (
        <>
          <SearchCertificates shouldRender={shouldRenderCertificates} search={certificates.searchCertificates} />
          <SearchHeats shouldRender={shouldRenderHeats} search={heats.searchHeats} />
          <SearchSupplierCertificates
            shouldRender={shouldRenderSupplierCertificates}
            search={supplierCertificates.searchSupplierCertificates}
          />
        </>
      )}
      {useQdocs && <SearchQDocs shouldRender={shouldRenderQdocs} search={qdocs.searchQdocs} />}
    </>
  );
}
