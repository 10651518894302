import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Unauthorized from '../Layout/Unauthoried.jsx';

import fetchSteelworksAsync from '../../store/steelworks/fetchSteelworks';
import postSteelworkAsync from '../../store/editSteelwork/postSteelwork';

import { BaseWrite } from './BaseWrite.jsx';
import { useAuth } from 'auth/useAuth.js';

export function Steelwork() {
  const steelworks = useSelector(state => state.steelworks);
  const editSteelwork = useSelector(state => state.editSteelwork);
  const dispatch = useDispatch();

  const { isAdmin } = useAuth();

  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const fetchData = useCallback(async () => {
    await dispatch(fetchSteelworksAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (steelworks?.data) {
      setItems(steelworks.data);
      setAllItems(steelworks.data);
    }
  }, [steelworks]);

  const updateItems = useCallback(items => {
    setItems([...items]);
  }, []);

  const save = useCallback(
    async item => {
      await dispatch(postSteelworkAsync(item));
      await fetchData();
    },
    [dispatch, fetchData]
  );

  if (!isAdmin()) {
    return <Unauthorized />;
  }
  return (
    <BaseWrite
      name='Steelworks'
      items={items}
      allItems={allItems}
      save={save}
      saveResult={editSteelwork}
      refreshData={fetchData}
      setItems={updateItems}
      isAdmin={isAdmin}
    />
  );
}
