import React, { Component } from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout.jsx';
import { Home } from './components/Layout/Home.jsx';
import { HeatDetails } from './components/Heats/HeatDetails.jsx';
import { CertificateDetails } from './components/Certificates/CertificateDetails.jsx';
import { MtrDetails } from './components/Mtr/MtrDetails.jsx';
import { EditHeat } from './components/Heats/EditHeat.jsx';
import { EditSupplierCertificate } from './components/SupplierCertificates/EditSupplierCertificate.jsx';
import { EditMtr } from './components/Mtr/EditMtr.jsx';
import { CreateAdHocMtr } from './components/Mtr/CreateAdHocMtr';
import { SupplierCertificateDetails } from './components/SupplierCertificates/SupplierCertificateDetails.jsx';
import { EditAlfaLavalCertificate } from './components/Certificates/EditAlfaLavalCertificate.jsx';
import { SearchAll } from 'components/Search';
import { CertificateSourceDetails } from './components/CertificateSource/CertificateSourceDetails.jsx';
import { MtrSourceDetails } from './components/CertificateSource/MtrSourceDetails.jsx';
import { QDocDetails } from './components/QDoc/QDocDetails.jsx';
import { EditQDoc } from './components/QDoc/EditQDoc.jsx';
import { Steelwork } from './components/Admin/Steelwork.jsx';
import { Manufacturer } from './components/Admin/Manufacturer.jsx';
import { Supplier } from './components/Admin/Supplier.jsx';
import { AsmeCertification } from './components/Admin/AsmeCertification.jsx';
import './App.css';

class App extends Component {
  render() {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    return (
      <BrowserRouter basename={baseUrl}>
        <Switch>
          <Route path='/certificate-source/:id*' component={CertificateSourceDetails} />
          <Route path='/mtr-source/:id*' component={MtrSourceDetails} />

          <Layout className='App'>
            <Route exact path='/' component={Home} />
            <Route exact path='/home' component={Home} />
            <Route path='/heat/:id*/details' component={HeatDetails} />
            <Route path='/edit-heat/:id?' component={EditHeat} />
            <Route path='/edit-supplier-certificate/:id?' component={EditSupplierCertificate} />
            <Route exact path='/supplier-certificate/:id*/details' component={SupplierCertificateDetails} />
            <Route path='/edit-certificate/:id?' component={EditAlfaLavalCertificate} />
            <Route path='/edit-mtr/:id?' component={EditMtr} />
            <Route path='/adhoc-mtr' component={CreateAdHocMtr} />
            <Route exact path='/certificate/:id*/details' component={CertificateDetails} />
            <Route exact path='/mtr/:id*/details' component={MtrDetails} />
            <Route
              exact
              path='/search-certificates'
              render={routeProps => <SearchAll {...routeProps} useQdocs={false} useCerts={true} />}
            />
            <Route exact path='/qdoc/:id*/details' component={QDocDetails} />
            <Route path='/edit-qdoc/:id?' component={EditQDoc} />
            <Route
              exact
              path='/search-qdocs'
              render={routeProps => <SearchAll {...routeProps} useQdocs={true} useCerts={false} />}
            />
            <Route path='/admin/manufacturer' component={Manufacturer} />
            <Route path='/admin/steelwork' component={Steelwork} />
            <Route path='/admin/supplier' component={Supplier} />
            <Route path='/admin/asme-certification' component={AsmeCertification} />
          </Layout>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
