import React, { Component, Fragment } from 'react';
import DecimalInput from '../DecimalInput/DecimalInput.jsx';

export default class ChemicalRangesInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chemicalValues: []
    };
  }

  componentDidMount() {
    this.setState({
      chemicalValues: this.props.chemicalValues
    });
  }

  shouldComponentUpdate(nextProps, _nextState) {
    if (this.props.isDisabled !== nextProps.isDisabled) {
      return true;
    }
    if (this.props.materials !== nextProps.materials) {
      return true;
    }
    if (this.props.chemicalRanges !== nextProps.chemicalRanges) {
      return true;
    }

    return false;
  }

  updateChemicalProperty(id, value) {
    let props = [];
    if (this.state.chemicalValues.length) {
      props = this.state.chemicalValues;
    }

    const index = props.map(e => e.element).indexOf(id);
    if (index > -1) {
      props[index].value = value;
    } else {
      props.push({ element: id, value: value });
    }
    this.setState({ chemicalValues: props });
    this.props.updateChemicalProperties(props);
  }

  updateDisableChemicalProperties() {
    this.props.disableComponent();
  }

  renderChemicalProperties(range, itemsPerRow, values) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += itemsPerRow) {
      const props = range.slice(i, i + itemsPerRow);
      output.push(this.renderSetOfChemicalProperties(props, i, values));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfChemicalProperties(props, i, values) {
    return (
      <div key={i + Date.now()} className='form-row col-sm-12'>
        {props.map(prop => this.renderChemicalProperty(prop, values), this)}
      </div>
    );
  }

  renderChemicalProperty(prop, values) {
    let value = null;
    const result = values.filter(x => x.element === prop.element);
    if (result.length) {
      value = result[0].value;
    }
    if (!prop) {
      return null;
    }
    return (
      <DecimalInput
        key={prop.element}
        property={prop}
        onChange={this.updateChemicalProperty.bind(this)}
        value={value}
      />
    );
  }

  render() {
    if (!this.props.chemicalRanges) {
      return null;
    }
    return (
      <Fragment>
        <div className='form-row col-sm-12 align-items-center'>
          <div className='col-auto'>
            <h5>Chemical data</h5>
          </div>
          <br />
          <div className='col-auto'>
            <div className='form-row col-sm-12'>
              <div className='form-check' title='Check Skip if you want to ignore Chemical data'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.props.isDisabled}
                  onChange={this.updateDisableChemicalProperties.bind(this)}
                  id='disableChemicalProperties'
                />
                <label className='form-check-label' htmlFor='disableChemicalProperties'>
                  Skip
                </label>
              </div>
            </div>
          </div>
        </div>
        {!this.props.isDisabled &&
          this.renderChemicalProperties(this.props.chemicalRanges, 4, this.props.chemicalValues)}
      </Fragment>
    );
  }
}
