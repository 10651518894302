import React, { useCallback } from 'react';

export function EditQDocHeader({ id, revision, invalid, updateInvalid }) {
  const onInvalid = useCallback(() => updateInvalid(), [updateInvalid]);

  return (
    <>
      <div className='form-row col-sm-12'>
        <h3>Q-doc</h3>
      </div>
      <div className='form-row col-sm-8'>
        <div className='col-sm-10'>
          {id && (
            <h5>
              Id: {id}, Revision: {revision}
            </h5>
          )}
        </div>
        <div className='col-sm-2 d-flex justify-content-end'>
          <div className='d-flex flex-row align-items-stretch'>
            <input className='form-check-input' type='checkbox' checked={invalid} id='invalid' onChange={onInvalid} />
            <label className='form-check-label h5' htmlFor='invalid'>
              Invalid
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
