import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import MechanicalRangesDecimalInput from './MechanicalRangesDecimalInput.jsx';

class MechanicalRangesInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeValues: []
    };
  }

  componentDidMount() {
    this.setState({ rangeValues: this.props.values });
  }

  shouldComponentUpdate(nextProps, _nextState) {
    if (this.props.isDisabled !== nextProps.isDisabled) {
      return true;
    }
    if (this.props.ranges.data && this.props.ranges.data.length) {
      if (this.props.ranges.data.length === nextProps.ranges.data.length) {
        return false;
      }
    }
    return true;
  }

  updateDisable() {
    this.props.disableComponent();
  }

  updateProperty(id, validatorId, value) {
    if (!id || !validatorId || !value) {
      return;
    }
    let props = [];
    if (this.state.rangeValues.length) {
      props = this.state.rangeValues;
    }

    const index = props.map(e => e.id).indexOf(id);
    if (index > -1) {
      props[index].id = id;
      props[index].value = value;
      props[index].validatorId = validatorId;
    } else {
      props.push({ id: id, validatorId: validatorId, value: value });
    }
    this.setState({ rangeValues: props });
    this.props.update(props);
  }

  renderProperties(range, itemsPerRow) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += itemsPerRow) {
      const props = range.slice(i, i + itemsPerRow);
      output.push(this.renderSetOfProperties(props, i, this.props.values));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfProperties(props, i, values) {
    return (
      <div key={i + Date.now()} className='form-row col-sm-12'>
        {props.map(prop => this.renderProperty(prop, values), this)}
      </div>
    );
  }

  renderProperty(prop, values) {
    const result = values.filter(x => x.id === prop.id);
    let value = null;
    let unitId = null;
    if (result.length) {
      value = result[0].value;
      unitId = result[0].validatorId;
    }
    if (!prop) {
      return null;
    }
    return (
      <MechanicalRangesDecimalInput
        key={prop.id}
        property={prop}
        onChange={this.updateProperty.bind(this)}
        value={value}
        unitId={unitId}
      />
    );
  }

  render() {
    if (!this.props.ranges.data || !this.props.ranges.data.length) {
      return null;
    }
    const ranges = this.props.ranges.data;

    return (
      <Fragment>
        <div className='form-row col-sm-12 align-items-center'>
          <div className='col-auto'>
            <h5>Mechanical data</h5>
          </div>
          <br />
          <div className='col-auto'>
            <div className='form-row col-sm-12'>
              <div className='form-check' title='Check Skip if you want to ignore Mechanical data'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.props.isDisabled}
                  onChange={this.updateDisable.bind(this)}
                  id='disableProperties'
                />
                <label className='form-check-label' htmlFor='disableProperties'>
                  Skip
                </label>
              </div>
            </div>
          </div>
        </div>
        {!this.props.isDisabled && this.renderProperties(ranges, 2)}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(MechanicalRangesInputs);
