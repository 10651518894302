import {
  POST_ASMECERTIFICATION_PENDING,
  POST_ASMECERTIFICATION_SUCCESS,
  POST_ASMECERTIFICATION_ERROR
} from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function asmeCertificationReducer(state = initialState, action) {
  switch (action.type) {
    case POST_ASMECERTIFICATION_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_ASMECERTIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: null
      };
    case POST_ASMECERTIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: undefined
      };
    default:
      return state;
  }
}
