import React, { Fragment, useCallback, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fetchHeatAsync from '../../store/heat/fetchHeat';
import fetchCertificateTypesAsync from '../../store/certificateTypes/fetchCertificateTypes';
import postHeatAsync from '../../store/editHeat/postHeat';
import { postEditHeatClear as clearHeatAction } from '../../store/editHeat/actions';
import fetchChemicalRangesAsync from '../../store/chemicalRanges/fetchChemicalRanges';
import fetchSteelworksAsync from '../../store/steelworks/fetchSteelworks';
import fetchSuppliersAsync from '../../store/suppliers/fetchSuppliers';
import fetchManufacturersAsync from '../../store/manufacturers/fetchManufacturers';
import fetchMaterialsAsync from '../../store/materials/fetchMaterials';
import fetchBasicHeatsAsync from '../../store/basicHeats/fetchBasicHeats';
import Materials from '../Materials/Materials.jsx';
import ChemicalRangesInputs from '../ChemicalRanges/ChemicalRangesInputs.jsx';
import Dropdown from '../Dropdown/Dropdown.jsx';
import TextInput from '../TextInput/TextInput.jsx';
import SaveModal from '../Modals/SaveModal.jsx';
import Spinner from '../Layout/Spinner';
import WarningModal from '../Modals/WarningModal.jsx';
import reportValidity from 'report-validity';
import Unauthorized from '../Layout/Unauthoried.jsx';
import './EditHeat.css';
import { useHistory, useParams, useLocation } from 'react-router';
import { useAuth } from 'auth/useAuth';

const initialState = {
  heatId: undefined,
  heatNumber: undefined,
  heatValidated: false,
  heatsSearched: false,
  heatsSearchValue: '',
  selectedMaterials: [],
  selectedCertificateTypeId: undefined,
  selectedSupplierId: undefined,
  selectedSteelworkId: undefined,
  selectedManufacturerId: undefined,
  testNumber: undefined,
  comment: undefined,
  chemicalProperties: [],
  revision: 0,
  invalid: false,

  disabledChemicalProperties: false,
  showSaveModal: false,
  showDuplicateHeatModal: false,
  saveClicked: false
};

export function EditHeat() {
  const [state, setState] = React.useState(initialState);

  const form = useRef();

  const { hasElevatedRole } = useAuth();

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { state: { refreshState } = { refreshState: false } } = useLocation();

  const heat = useSelector(state => state.heat);
  const basicHeats = useSelector(state => state.basicHeats);
  const manufacturers = useSelector(state => state.manufacturers);
  const steelworks = useSelector(state => state.steelworks);
  const suppliers = useSelector(state => state.suppliers);
  const certificateTypes = useSelector(state => state.certificateTypes);
  const materials = useSelector(state => state.materials);
  const chemicalRanges = useSelector(state => state.chemicalRanges);
  const editHeat = useSelector(state => state.editHeat);

  const clearState = useCallback(() => {
    setState({
      ...initialState
    });
    dispatch(clearHeatAction());
  }, [dispatch]);

  const clearHeat = useCallback(() => {
    clearState();
    history.replace('/edit-heat');
  }, [clearState, history]);

  useEffect(() => {
    if (!id || refreshState) {
      clearHeat();
    }
  }, [id, refreshState, clearHeat]);

  useEffect(() => {
    if (id && id !== state.heatId) {
      dispatch(fetchHeatAsync(id));
    }
  }, [dispatch, id, state.heatId]);

  useEffect(() => {
    dispatch(fetchSuppliersAsync('heat'));
    dispatch(fetchCertificateTypesAsync());
    dispatch(fetchSteelworksAsync());
    dispatch(fetchManufacturersAsync());
  }, [dispatch]);

  const filteredManufacturers = useMemo(
    () => manufacturers?.data?.filter(x => !x.invalid || x.id === state.selectedManufacturerId) ?? [],
    [manufacturers, state.selectedManufacturerId]
  );

  const filteredSteelworks = useMemo(
    () => steelworks?.data?.filter(x => !x.invalid || x.id === state.selectedSteelworkId) ?? [],
    [steelworks, state.selectedSteelworkId]
  );

  const filteredSuppliers = useMemo(
    () => suppliers?.data?.filter(x => !x.invalid || x.id === state.selectedSupplierId) ?? [],
    [suppliers, state.selectedSupplierId]
  );

  const heatExistsInStorage = useCallback(
    (heatNumber, supplierId) => {
      return basicHeats?.heats?.some(x => {
        if (
          x.heatNumber.toUpperCase() === heatNumber.trim().toUpperCase() &&
          x.supplier.id === supplierId &&
          !x.invalid
        ) {
          return true;
        }
        return false;
      });
    },
    [basicHeats]
  );

  useEffect(() => {
    if (
      !basicHeats.pending &&
      basicHeats.heats &&
      !!state.heatNumber &&
      !!state.selectedSupplierId &&
      state.heatsSearched
    ) {
      const exists = heatExistsInStorage(state.heatNumber, state.selectedSupplierId);
      setState(prevState => ({
        ...prevState,
        heatValidated: !exists
      }));
    }
  }, [basicHeats, state.heatNumber, state.selectedSupplierId, state.heatsSearched, heatExistsInStorage]);

  useEffect(() => {
    if (heat.pending || !heat.heat) {
      return;
    }

    dispatch(fetchMaterialsAsync(heat.heat.certificateType.id));
    dispatch(fetchChemicalRangesAsync(heat.heat.selectedStandards));

    const disabledChemicalProperties = heat?.heat?.chemicalProperties?.length === 0;

    setState({
      heatId: heat.heat.id,
      heatNumber: heat.heat.heatNumber,
      heatValidated: true,
      heatsSearched: false,
      selectedMaterials: heat.heat.selectedStandards,
      selectedCertificateTypeId: heat.heat.certificateType.id,
      selectedSupplierId: heat.heat.supplier.id,
      selectedSteelworkId: heat.heat.steelWork.id,
      selectedManufacturerId: heat.heat.manufacturer.id,
      testNumber: heat.heat.testNumber,
      comment: heat.heat.comment,
      chemicalProperties: heat.heat.chemicalProperties,
      revision: heat.heat.revision,
      disabledChemicalProperties: disabledChemicalProperties,
      invalid: heat.heat.invalid
    });
  }, [heat, dispatch]);

  const searchHeats = useCallback(
    value => {
      if (!value) return;
      dispatch(fetchBasicHeatsAsync(value));
      setState(prevState => ({ ...prevState, heatsSearched: true, heatsSearchValue: value }));
    },
    [dispatch]
  );

  const searchHeatsByNumber = useCallback(() => {
    searchHeats(state.heatNumber);
  }, [searchHeats, state]);

  const setSelectedChemicalProperties = useCallback(chemicalProperties => {
    setState(prevState => ({ ...prevState, chemicalProperties }));
  }, []);

  const updateSupplier = useCallback(supplierId => {
    setState(prevState => ({ ...prevState, selectedSupplierId: supplierId }));
  }, []);

  const updateSteelwork = useCallback(steelworkId => {
    setState(prevState => ({ ...prevState, selectedSteelworkId: steelworkId }));
  }, []);

  const updateManufacturer = useCallback(manufacturerId => {
    setState(prevState => ({ ...prevState, selectedManufacturerId: manufacturerId }));
  }, []);

  const updateCertificateTypeAndRenderMaterials = useCallback(
    async certificateTypeId => {
      if (!certificateTypeId) {
        return;
      }
      setState(prevState => ({ ...prevState, selectedCertificateTypeId: certificateTypeId, chemicalProperties: [] }));

      dispatch(fetchMaterialsAsync(certificateTypeId));
    },
    [dispatch]
  );

  const updateInvalid = useCallback(() => {
    setState(prevState => ({ ...prevState, invalid: !prevState.invalid }));
  }, []);

  const updateComment = useCallback(e => {
    if (!e.target) {
      return;
    }
    const value = e.target.value;
    setState(prevState => ({ ...prevState, comment: value }));
  }, []);

  const updateHeatNumber = useCallback(heatNumber => {
    setState(prevState => ({ ...prevState, heatNumber: heatNumber.trim() }));
  }, []);

  const updateTestNumber = useCallback(testNumber => {
    setState(prevState => ({ ...prevState, testNumber: testNumber }));
  }, []);

  const autoSelectedMaterial = useMemo(() => {
    if (materials.data) {
      return materials.data?.length === 1;
    }
    return false;
  }, [materials]);

  const isFormValid = useCallback(() => {
    const formValid = form.current?.checkValidity();
    if (formValid && autoSelectedMaterial) {
      return true;
    }
    return formValid;
  }, [form, autoSelectedMaterial]);

  const loadHeat = useCallback(
    async heatId => {
      if (id !== heatId) history.replace('/edit-heat/' + heatId);
      dispatch(fetchHeatAsync(heatId));
    },
    [history, id, dispatch]
  );

  const toggleDuplicateModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showDuplicateHeatModal: !prevState.showDuplicateHeatModal,
      heatsSearched: false,
      hetValidated: false
    }));
  }, []);

  const closeSaveModal = useCallback(() => {
    setState(prevState => ({ ...prevState, showSaveModal: false, saveClicked: false }));
  }, []);

  const beginSave = useCallback(() => {
    reportValidity(form.current);
    if (!isFormValid()) {
      return;
    }
    setState(prevState => ({ ...prevState, showSaveModal: true, saveClicked: false }));
  }, [isFormValid]);

  const renderDuplicateModal = useCallback(() => {
    if (!state.heatsSearched || !basicHeats.heats?.length) {
      return null;
    }

    const exists = heatExistsInStorage(state.heatNumber, state.selectedSupplierId);
    if (!exists) {
      return null;
    }

    const heat = basicHeats.heats.filter(
      x =>
        x.heatNumber.toUpperCase() === state.heatNumber.trim().toUpperCase() &&
        x.supplier.id === state.selectedSupplierId
    )[0];
    const body = (
      <div>
        <div>
          There is already an existing heat with the same heat number and supplier. Please change the heat name or edit
          the existing heat.
        </div>
        <Link to={'heat/' + heat.id + '/details'} target='_blank' rel='noopener noreferrer'>
          {heat.heatNumber} {heat.supplier.name}
        </Link>
      </div>
    );
    const header = 'Warning. Duplicate Heat found';

    return (
      <WarningModal
        show={true}
        toggle={toggleDuplicateModal}
        header={header}
        body={body}
        continue={() => loadHeat(heat.id)}
        continueButton='Edit'
      />
    );
  }, [loadHeat, heatExistsInStorage, toggleDuplicateModal, state, basicHeats]);

  const saveHeat = useCallback(() => {
    if (!isFormValid()) {
      return;
    }
    setState(prevState => ({ ...prevState, saveClicked: true }));

    let materialStandards = state.selectedMaterials;
    if (materials.data?.length === 1) {
      materialStandards = [
        {
          materialStandardId: materials.data[0].id,
          materialGradeId: materials.data[0].grades[0].id,
          materialStandardRevision: materials.data[0].standardRevision
        }
      ];
    }
    let chemicalProperties = state.chemicalProperties;
    if (state.disabledChemicalProperties) {
      chemicalProperties = [];
    }

    const revision = state.revision + 1;

    const heat = {
      id: state.heatId,
      revision: revision,
      heatNumber: state.heatNumber,
      supplierId: state.selectedSupplierId,
      steelWorkId: state.selectedSteelworkId,
      manufacturerId: state.selectedManufacturerId,
      certificateTypeId: state.selectedCertificateTypeId,
      standards: materialStandards,
      testNumber: state.testNumber,
      comment: state.comment,
      chemicalProperties: chemicalProperties,
      invalid: state.invalid,
      skippedChemicalProperties: state.disabledChemicalProperties
    };

    dispatch(postHeatAsync(heat));

    setState(prevState => ({ ...prevState, revision }));
  }, [dispatch, isFormValid, state, materials]);

  useEffect(() => {
    if (editHeat.pending || !editHeat.data) return;

    if (!editHeat?.error) {
      history.replace('/edit-heat/' + editHeat.data.id);

      setState(prevState => ({ ...prevState, heatId: editHeat.data.id }));
    }
  }, [editHeat, history]);

  const renderSaveModal = useCallback(() => {
    if (!state.showSaveModal) {
      return null;
    }

    let body = '';
    if (!state.saveClicked) {
      body = 'Do you want to save the heat?';
    } else if (editHeat.data && !editHeat.pending) {
      body = (
        <div>
          Successfully saved the Heat:{' '}
          <Link to={'/heat/' + editHeat.data.id + '/details'} target='_blank' rel='noopener noreferrer'>
            {' '}
            {editHeat.data.heatNumber} {editHeat.data.supplierName}
          </Link>
        </div>
      );
    } else if (editHeat?.error) {
      const error = editHeat.error;
      body = (
        <Fragment>
          <div>Unable to save the heat.</div>
          <div>Reason: {error.message}.</div>
          {error.innerErrors.map(x => {
            return <div key={x.message}>{x.message}</div>;
          })}
        </Fragment>
      );
    }

    return (
      <SaveModal
        show={state.showSaveModal}
        toggle={closeSaveModal}
        save={saveHeat}
        createAnother={clearHeat}
        header='Save Heat'
        body={body}
        pending={editHeat.pending}
        data={editHeat.data}
        large={editHeat?.error}
      />
    );
  }, [state, editHeat, closeSaveModal, saveHeat, clearHeat]);

  const setSelectedMaterialsAndFetchChemicalProperties = useCallback(
    materials => {
      if (!materials) {
        return;
      }

      setState(prevState => ({ ...prevState, selectedMaterials: materials, chemicalProperties: [] }));

      if (materials?.length) {
        dispatch(fetchChemicalRangesAsync(materials));
      }
    },
    [dispatch]
  );

  const disableChemicalProperties = useCallback(() => {
    if (!state.disabledChemicalProperties) {
      dispatch(fetchChemicalRangesAsync(state.selectedMaterials));
    }
    setState(prevState => ({ ...prevState, disabledChemicalProperties: !prevState.disabledChemicalProperties }));
  }, [dispatch, state]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (heat.pending) {
    return <Spinner />;
  }
  return (
    <div>
      <form ref={form} style={{ opacity: state.showSaveModal ? 0.5 : 1 }}>
        <br />
        <div className='form-row col-sm-12'>
          <h3>Heat</h3>
        </div>
        <div className='form-row col-sm-12'>
          <div className='col-sm-8'>
            {state.heatId && (
              <h5>
                Id: {state.heatId}, Revision: {state.revision}
              </h5>
            )}
          </div>
          <div
            className='form-check'
            title='Once invalid is checked you cannot use the Heat for new Certificates. You can however uncheck it again and save.'
          >
            <input
              className='form-check-input'
              type='checkbox'
              checked={state.invalid}
              id='invalid'
              onChange={updateInvalid}
            />
            <h5 className='form-check-label' htmlFor='invalid'>
              Invalid
            </h5>
          </div>
        </div>
        <br />
        <div className='form-row col-sm-12'>
          <h5>General data</h5>
        </div>
        <div className='form-row col-sm-12 d-flex align-items-end'>
          <TextInput
            elementId='heatNumber'
            entityDescription='Heat number'
            update={updateHeatNumber}
            required={true}
            value={state.heatNumber}
          />
          <Dropdown
            data={filteredSuppliers}
            elementName='suppliers'
            entityDescription='Supplier'
            valueProperty='name'
            update={updateSupplier}
            required={true}
            value={state.selectedSupplierId}
            loading={suppliers.pending}
            error={suppliers?.error}
          />
          <div className='form-group'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={searchHeatsByNumber}
              disabled={!state.heatNumber || !state.selectedSupplierId}
            >
              Confirm <i className={basicHeats.pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
            </button>
          </div>
        </div>
        {state.heatValidated && (
          <Fragment>
            <div className='form-row col-sm-12'>
              <Dropdown
                data={filteredSteelworks}
                elementName='steelworks'
                entityDescription='Steelwork'
                valueProperty='name'
                update={updateSteelwork}
                required={true}
                value={state.selectedSteelworkId}
                error={steelworks?.error}
              />
              <Dropdown
                data={filteredManufacturers}
                elementName='manufacturers'
                entityDescription='Manufacturer'
                valueProperty='name'
                update={updateManufacturer}
                required={true}
                value={state.selectedManufacturerId}
                error={manufacturers?.error}
              />
            </div>
            <div className='form-row col-sm-12'>
              <TextInput
                elementId='testNumber'
                entityDescription='Test number'
                update={updateTestNumber}
                required={false}
                value={state.testNumber}
              />
            </div>
            <div className='form-row col-sm-12'>
              <div className='form-group col-sm-8'>
                <label htmlFor='comment'>Comment</label>
                <textarea
                  type='text'
                  className='form-control'
                  rows='3'
                  id='comment'
                  onChange={updateComment}
                  value={state.comment}
                />
              </div>
            </div>
            <div className='form-row col-sm-12'>
              <Dropdown
                data={certificateTypes}
                elementName='certificateTypes'
                entityDescription='Certificate Type'
                valueProperty='description'
                loading={materials.pending}
                update={updateCertificateTypeAndRenderMaterials}
                required={true}
                value={state.selectedCertificateTypeId}
                disableComponent={disableChemicalProperties}
                error={certificateTypes?.error}
              />
            </div>
            {state.selectedCertificateTypeId && !autoSelectedMaterial && (
              <Materials
                data={materials}
                updateMaterials={setSelectedMaterialsAndFetchChemicalProperties}
                loading={chemicalRanges.pending}
                required={true}
                selectedMaterials={state.selectedMaterials}
                error={materials?.error}
              />
            )}
            {state.selectedMaterials?.length > 0 && (
              <ChemicalRangesInputs
                certificateTypeId={state.selectedCertificateTypeId}
                materials={state.selectedMaterials}
                chemicalRanges={chemicalRanges.data}
                updateChemicalProperties={setSelectedChemicalProperties}
                chemicalValues={state.chemicalProperties}
                disableComponent={disableChemicalProperties}
                isDisabled={state.disabledChemicalProperties}
              />
            )}

            <div className='form-row col-sm-12'>
              <div className='form-group col-auto'>
                <button
                  type='button'
                  title='Note that this button will reset the entered data...'
                  className='btn btn-secondary'
                  onClick={clearHeat}
                >
                  Clear
                </button>
              </div>
              <div className='form-group col-auto'>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={beginSave}
                  disabled={!autoSelectedMaterial && state.selectedMaterials?.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </form>
      {renderDuplicateModal()}
      {renderSaveModal()}
    </div>
  );
}
