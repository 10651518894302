import React, { useState, useCallback } from 'react';
import TextInput from '../../TextInput/TextInput.jsx';

export function EditQDocItems({ addItemNumber, selectedItems, removeItemNumber }) {
  const [itemNumber, setItemNumber] = useState();
  const [pending, setPending] = useState(false);

  const onUpdate = useCallback(item => {
    setItemNumber(item);
  }, []);

  const onAddItemNumber = useCallback(async () => {
    try {
      setPending(true);
      await addItemNumber(itemNumber);

      setItemNumber(undefined);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }, [addItemNumber, itemNumber]);

  return (
    <>
      <div className='form-row col-sm-12'>
        <h5>Items</h5>
      </div>
      <div className='form-row col-sm-12 d-flex align-items-end'>
        <TextInput
          elementId='serialId'
          entityDescription='Item number'
          update={onUpdate}
          value={itemNumber}
          required={selectedItems?.length === 0}
        />
        <div className='form-group'>
          <button type='button' className='btn btn-secondary' onClick={onAddItemNumber} disabled={!itemNumber}>
            <i className={pending ? 'fas fa-circle-notch fa-spin' : 'fa fa-plus'}></i>
          </button>
        </div>
      </div>
      {selectedItems?.length > 0 && (
        <div className='form-row col-sm-12'>
          <table className='table table-sm table-striped table-hover col-sm-4' id='itemsTable'>
            <tbody>
              {selectedItems.map(item => (
                <tr key={item.itemNumber}>
                  <td>
                    {item.itemNumber}
                    <i
                      className={item.warning ? 'fa fa-exclamation-triangle' : null}
                      title='The item was not found in GIM. You can still use the item.'
                    />
                  </td>
                  <td align='right'>
                    <i className='fa fa-minus' onClick={() => removeItemNumber(item)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
