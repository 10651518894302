import React, { Component, Fragment } from 'react';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  setSelected(e) {
    if (!e.target) {
      return;
    }

    this.setState = {
      value: e.target.value
    };
    this.props.update(e.target.value);
  }

  renderOptions(entities, value) {
    return (
      <Fragment>
        <option disabled value=''>
          Select {this.props.entityDescription}
        </option>
        {entities.map(data => this.renderOption(data, this.props.optionId, value))}
      </Fragment>
    );
  }

  renderOption(data, id, value) {
    if (data.invalid && data.id !== value) {
      return null;
    }
    if (!id) {
      id = 'id';
    }
    // Magic traverse of properties
    const props = this.props.valueProperty.split(',');
    const values = [];
    props.forEach((prop, index) => {
      const parts = prop.split('.');
      let current = data;
      parts.forEach(value => {
        if (current[value] !== undefined) {
          current = current[value];
          values[index] = current;
        }
      });
    });
    const invalid = data.invalid;
    const text = invalid ? `${values.join(', ')} (invalid)` : values.join(', ');
    return (
      <option key={data[id]} value={data[id]} disabled={invalid}>
        {text}
      </option>
    );
  }

  render() {
    let value = this.props.value;
    if (!value) {
      value = '';
    }
    let entities = [];
    if (!this.props.data) {
      return null;
    } else if (this.props.data && Array.isArray(this.props.data)) {
      entities = this.props.data;
    } else if (this.props.data.data && Array.isArray(this.props.data.data) && this.props.data.data.length) {
      entities = this.props.data.data;
    }
    let width = 'col-sm-4';
    if (this.props.width) {
      width = this.props.width;
    }
    const classes = 'form-group'.concat(' ', width);

    return (
      <Fragment>
        <div className={classes}>
          <label htmlFor={this.props.elementName}>
            {this.props.entityDescription} {this.props.required && '*'}
          </label>
          <select
            value={value}
            className='form-control'
            id={this.props.elementName}
            onChange={e => this.setSelected(e)}
            required={this.props.required}
            disabled={this.props.disabled}
          >
            {this.renderOptions(entities, value)}
          </select>
          {this.props.loading && (
            <div className='spinner-border spinner-border-sm' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          )}
          {this.props.error && (
            <div className='fa fa-exclamation-triangle' role='status' title='Unable to fetch data, please reload page'>
              <span className='sr-only'>Unable to fetch data, please reload page</span>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
