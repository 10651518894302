import 'bootstrap/dist/css/bootstrap.css';
import 'react-app-polyfill/ie11';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker.js';
import { configureStore } from './store/configureStore.js';
import { EnviromentProvider } from 'enviroment/Context';

const fetchConfig = async () => {
  try {
    const configFile = await fetch('env.json');
    const configValues = await configFile.json();

    return configValues;
  } catch (e) {
    console.log(e);
  }
};

const enviroment = await fetchConfig();

const msalConfig = {
  auth: {
    clientId: enviroment.AzureAd.ClientId,
    authority: enviroment.AzureAd.Authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const requestScopes = enviroment?.AzureAd?.RequestScopes?.split(',') ?? [];

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

const store = configureStore(msalInstance, requestScopes);

root.render(
  <StrictMode>
    <EnviromentProvider requestScope={requestScopes}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    </EnviromentProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
