export const POST_ASMECERTIFICATION_PENDING = 'POST_ASMECERTIFICATION_PENDING';
export const POST_ASMECERTIFICATION_SUCCESS = 'POST_ASMECERTIFICATION_SUCCESS';
export const POST_ASMECERTIFICATION_ERROR = 'POST_ASMECERTIFICATION_ERROR';

export function postAsmeCertificationPending() {
  return {
    type: POST_ASMECERTIFICATION_PENDING
  };
}

export function postAsmeCertificationSuccess() {
  return {
    type: POST_ASMECERTIFICATION_SUCCESS
  };
}

export function postAsmeCertificationError(error) {
  return {
    type: POST_ASMECERTIFICATION_ERROR,
    error: error
  };
}
