import React, { useCallback, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';

export function EditQDocSerial({ serialNumber, id, updateSerialNumber, searchQDocs }) {
  const onSerialNumberChange = useCallback(value => updateSerialNumber(value), [updateSerialNumber]);
  const [pending, setPending] = useState(false);

  const onSearch = useCallback(async () => {
    setPending(true);
    try {
      await searchQDocs();
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }, [searchQDocs]);

  return (
    <div className='form-row col-sm-12 d-flex align-items-end'>
      <TextInput
        elementId='serialId'
        entityDescription='Serial number'
        update={onSerialNumberChange}
        required={true}
        value={serialNumber}
        disabled={id}
        pattern='.{3,}'
        title='3 characters minimum'
      />
      <div className='form-group'>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={onSearch}
          disabled={serialNumber?.length < 3 || id}
        >
          Confirm <i className={pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
        </button>
      </div>
    </div>
  );
}
