import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import { PaginatedResult } from './PaginatedResult.jsx';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';

import './SearchCertificatesResult.css';
import { useAuth } from 'auth/useAuth.js';

export function SearchHeats({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchHeats);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  if (!shouldRender) {
    return null;
  }
  const totalCount = hasElevatedRole()
    ? data?.totalCount
    : data?.totalCount > data?.results?.length && data?.totalCount > 15
    ? `${15}+`
    : data?.results?.length;

  return (
    <Accordion className='mt-4'>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey='0'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='btn btn-link'>Heats</div>
            {error && <div style={{ color: 'red' }}>{error.message}</div>}
            <div>{pending ? <i className='fas fa-circle-notch fa-spin' /> : totalCount}</div>
          </div>
        </Accordion.Toggle>
        {data?.results?.length > 0 && (
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              {data?.results?.map((heat, i) => (
                <Row key={heat.heat.id + ' ' + i} heat={heat.heat} />
              ))}
              <br />
              <PaginatedResult totalCount={data?.totalCount} pageCount={pageCount} search={search} pending={pending} />
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
}

function Row({ heat }) {
  return (
    <Accordion>
      <Card className={heat.invalid ? 'invalid-card' : undefined}>
        <Accordion.Toggle as={Card.Header} eventKey='0'>
          <div className='row'>
            <div className='col-lg-3 col-md-3'>
              <b>Heat</b>
              <ul className='list-unstyled mb-md-0'>
                <li>
                  <SearchResult type={'heat'} id={heat.id} description={`${heat.heatNumber} ${heat.supplier}`} />
                </li>
              </ul>
            </div>
            <div className='col-lg-4 col-md-4'>
              <b>Type</b>
              <ul className='list-unstyled mb-md-0'>
                <li>{heat.certificateType}</li>
              </ul>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            <div className='container'>
              <div className='col-lg-4 col-md-4'>
                <b>Material standards</b>
                <ul className='list-unstyled mb-md-0'>
                  {heat.materialStandards &&
                    heat.materialStandards.map((material, index) => (
                      <li key={`${heat.id}_ms_${index}`}>
                        <div>{`${material.materialStandard} ${material.materialGrade}${
                          material.materialStandardRevision ? `, ${material.materialStandardRevision}` : ''
                        }`}</div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

function SearchResult({ type, id, description, invalid }) {
  return (
    <Link
      className={invalid ? 'invalid-certificate' : undefined}
      key={id}
      to={`/${type}/${id}/details`}
      target='_blank'
      rel='noopener noreferrer'
      onClick={stopPropagation}
      title={invalid ? 'The certificate is invalid' : undefined}
    >
      {`${description}`} {invalid && <i className='fa fa-exclamation m-1' />}
    </Link>
  );
}
