import React, { Component, Fragment } from 'react';
import TextInput from '../TextInput/TextInput.jsx';

export default class PurchaseOrderNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poNumber: null
    };
  }

  updatePoNumber(number) {
    this.setState({
      poNumber: number
    });
  }

  addPoNumber() {
    if (!this.state.poNumber) {
      return;
    }
    const poNumbers = this.props.numbers;
    if (poNumbers.some(x => x === this.state.poNumber)) {
      return;
    }

    poNumbers.push(this.state.poNumber);
    this.props.update(poNumbers);
  }

  removeSelectedPo(po) {
    const poNumbers = this.props.numbers;
    const index = poNumbers.indexOf(po);
    if (index < 0) {
      return;
    }
    poNumbers.splice(index, 1);

    this.props.update(poNumbers);
  }

  renderSelectedPoNumbers(poNumbers) {
    return poNumbers.map(po => {
      return (
        <tr key={po}>
          <td>{po}</td>
          <td align='right'>
            <i className='fa fa-minus' onClick={() => this.removeSelectedPo(po)}></i>
          </td>
        </tr>
      );
    });
  }

  render() {
    const numbers = this.props.numbers;
    return (
      <Fragment>
        <div className='form-row col-sm-12 d-flex align-items-end'>
          <TextInput
            elementId='poNumber'
            entityDescription='PO number'
            update={this.updatePoNumber.bind(this)}
            required={false}
            value={this.state.poNumber}
          />
          <div className='form-group'>
            <button type='button' className='btn btn-secondary' onClick={this.addPoNumber.bind(this)}>
              <i className='fa fa-plus'></i>
            </button>
          </div>
        </div>
        {numbers && numbers.length > 0 && (
          <div className='form-row col-sm-12'>
            <div className='col-sm-4'>
              <label htmlFor='poTable'>Selected PO numbers</label>
              <table className='table table-sm table-striped table-hover col' id='poTable'>
                <thead>
                  <tr>
                    <th>PO</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{this.renderSelectedPoNumbers(this.props.numbers)}</tbody>
              </table>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
