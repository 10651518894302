import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'api/useApi';

import fetchQDocAsync from '../../store/qdoc/fetchQDoc';
import fetchProductsAsync from '../../store/products/fetchProducts';
import fetchSuppliersAsync from '../../store/suppliers/fetchSuppliers';
import { fetchQDocClear } from '../../store/qdoc/actions';
import postQDocAsync from 'store/editQDoc/postQDoc';
import { postQDocClear } from 'store/editQDoc/actions';

import Unauthorized from '../Layout/Unauthoried.jsx';
import Spinner from '../Layout/Spinner';

import './EditQDoc.css';
import { EditQDocItems } from './EditQDoc/EditQDocItems';
import { EditQDocData } from './EditQDoc/EditQDocData.jsx';
import { EditQdocFileUpload } from './EditQDoc/EditQDocFileUpload';
import { SaveQDocModal } from './EditQDoc/SaveQdocModal';
import { DuplicateModal } from './EditQDoc/DuplicateModal';
import { EditQDocHeader } from './EditQDoc/EditQDocHeader';
import { EditQDocSerial } from './EditQDoc/EditQDocSerial';
import { EditQDocComment } from './EditQDoc/EditQDocComment';
import { ActionRow } from './EditQDoc/ActionRow';
import { useParams, useHistory, useLocation } from 'react-router';
import { useAuth } from 'auth/useAuth';

const initialState = {
  id: undefined,
  serialNumber: '',
  selectedItems: [],
  selectedProductGroupId: undefined,
  selectedProductTypeId: undefined,
  selectedSupplierId: undefined,
  fileId: undefined,
  fileName: '',
  comment: '',
  revision: 0,
  invalid: false
};

export function EditQDoc() {
  const [showSaveModal, setSaveModal] = useState(false);
  const [showDuplicateModal, setDuplicateModal] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [serialNumberConfirmed, setSerialNumberConfirmed] = useState(false);
  const [searchedQDocs, setSearchedQDocs] = useState();
  const [qDocForm, setQDocForm] = useState(initialState);
  const formRef = useRef();

  const suppliers = useSelector(state => state.suppliers);
  const products = useSelector(state => state.products);
  const qDoc = useSelector(state => state.qdoc);
  const uploadFile = useSelector(state => state.uploadFile);

  const { hasElevatedRole } = useAuth();
  const editQDoc = useSelector(state => state.editQDoc);

  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { getJson } = useApi();

  const clear = useCallback(() => {
    dispatch(fetchQDocClear());
    dispatch(postQDocClear());

    setSerialNumberConfirmed(false);
    setSearchedQDocs(undefined);
    setSaveClicked(false);
    setQDocForm(initialState);
    history.replace('/edit-qdoc');
  }, [dispatch, history]);

  useEffect(() => {
    if (id && id !== qDocForm.id) {
      dispatch(fetchQDocAsync(id));
    }
  }, [id, dispatch, qDocForm.id]);

  useEffect(() => {
    if (!id || state?.refreshState) {
      clear();
    }
  }, [state, clear, id]);

  useEffect(() => {
    if (editQDoc.pending || !editQDoc?.data) return;

    if (id === undefined) {
      setQDocForm(prevState => ({ ...prevState, id: editQDoc.data }));
    }

    if (id === undefined && editQDoc?.data) {
      history.replace(`/edit-qdoc/${editQDoc?.data}`);
    }
  }, [id, editQDoc, history]);

  useEffect(() => {
    dispatch(fetchProductsAsync());
    dispatch(fetchSuppliersAsync('qdoc'));
  }, [dispatch]);

  useEffect(() => {
    if (qDoc.pending || !qDoc.data) {
      return;
    }

    const items = qDoc.data?.itemNumbers?.map(x => {
      return { itemNumber: x };
    });

    setQDocForm({
      id: qDoc.data?.id,
      serialNumber: qDoc.data?.serialNumber,
      selectedItems: items,
      selectedProductGroupId: qDoc.data?.product.productGroup.id,
      selectedProductTypeId: qDoc.data?.product.productType.id,
      selectedSupplierId: qDoc.data?.supplier.id,
      fileName: qDoc.data?.fileName,
      comment: qDoc.data?.comment,
      revision: qDoc.data?.revision,
      invalid: qDoc.data?.invalid ? true : false
    });

    setSerialNumberConfirmed(!!qDoc.data?.serialNumber);
  }, [qDoc, id]);

  const updateForm = useCallback((key, value) => {
    setQDocForm(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const searchQDocs = useCallback(async () => {
    if (qDocForm?.serialNumber && qDocForm?.serialNumber.length > 0) {
      const docs = await getJson(`qdoc/serial-number/${encodeURIComponent(qDocForm?.serialNumber)}`);
      setSearchedQDocs(docs);
      const serialNumberConfirmed = !docs.some(
        x => x.serialNumber.toUpperCase() === qDocForm.serialNumber.toUpperCase() && !x.invalid
      );
      setSerialNumberConfirmed(serialNumberConfirmed);
      setDuplicateModal(!serialNumberConfirmed);
    }
  }, [qDocForm, getJson]);

  const addItemNumber = useCallback(
    async itemNumber => {
      return getJson(`item/${encodeURIComponent(itemNumber)}`, true)
        .then(data => {
          setQDocForm(prevState => ({
            ...prevState,
            selectedItems: [...(prevState.selectedItems ?? []), { itemNumber: data?.itemNumber }]
          }));
        })
        .catch(() => {
          setQDocForm(prevState => ({
            ...prevState,
            selectedItems: [...(prevState.selectedItems ?? []), { itemNumber: itemNumber, warning: true }]
          }));
        });
    },
    [getJson]
  );

  const removeItemNumber = useCallback(
    item => {
      const index = qDocForm.selectedItems.indexOf(item);
      if (index < 0) {
        return;
      }
      const items = qDocForm.selectedItems;
      items.splice(index, 1);
      setQDocForm(prevState => ({ ...prevState, selectedItems: items }));
    },
    [qDocForm.selectedItems]
  );

  const beginSave = useCallback(() => {
    if (formRef.current?.reportValidity() && qDocForm.selectedItems.length > 0) {
      setSaveModal(true);
    }
  }, [qDocForm.selectedItems.length]);

  const toggleSaveModal = useCallback(() => {
    setSaveModal(prevState => !prevState);
    setSaveClicked(false);
    dispatch(postQDocClear());
  }, [dispatch]);

  const toggleDuplicateModal = useCallback(() => setDuplicateModal(prevState => !prevState), []);

  const save = useCallback(async () => {
    const itemNumbers = qDocForm.selectedItems.map(x => x.itemNumber);
    const revision = qDocForm.revision + 1;
    const data = {
      id: qDocForm.id,
      serialNumber: qDocForm.serialNumber,
      supplierId: qDocForm.selectedSupplierId,
      productGroupId: qDocForm.selectedProductGroupId,
      productTypeId: qDocForm.selectedProductTypeId,
      comment: qDocForm.comment,
      fileName: qDocForm.fileName,
      fileId: qDocForm.fileId,
      itemNumbers: itemNumbers,
      revision: revision,
      invalid: qDocForm.invalid
    };

    dispatch(postQDocAsync(data));
    setQDocForm(prevState => ({ ...prevState, revision: revision }));
    setSaveClicked(true);
  }, [qDocForm, setQDocForm, dispatch]);

  const load = useCallback(
    async id => {
      dispatch(fetchQDocAsync(id));

      history.replace('/edit-qdoc/' + id);
    },
    [dispatch, history]
  );

  const onSetFileData = useCallback(
    (fileName, fileId) => {
      updateForm('fileName', fileName);
      updateForm('fileId', fileId);
    },
    [updateForm]
  );

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }

  if (qDoc?.pending) {
    return <Spinner />;
  }

  return (
    <div className='pt-4'>
      <form
        ref={formRef}
        onSubmit={e => {
          e.preventDefault();
        }}
        style={{ opacity: showSaveModal ? 0.5 : 1 }}
      >
        <EditQDocHeader
          id={qDocForm.id}
          revision={qDocForm.revision}
          invalid={qDocForm.invalid}
          updateInvalid={() => updateForm('invalid', !qDocForm.invalid)}
        />
        <EditQDocSerial
          serialNumber={qDocForm?.serialNumber}
          id={qDocForm.id}
          searchQDocs={searchQDocs}
          updateSerialNumber={serialNumber => updateForm('serialNumber', serialNumber)}
        />

        {serialNumberConfirmed && (
          <Fragment>
            <EditQDocItems
              selectedItems={qDocForm.selectedItems}
              addItemNumber={addItemNumber}
              removeItemNumber={removeItemNumber}
            />
            <EditQDocData
              selectedSupplierId={qDocForm?.selectedSupplierId}
              suppliers={suppliers}
              updateSupplier={id => updateForm('selectedSupplierId', id)}
              products={products}
              updateProductGroup={id => updateForm('selectedProductGroupId', id)}
              updateProductType={id => updateForm('selectedProductTypeId', id)}
              selectedProductGroupId={qDocForm?.selectedProductGroupId}
              selectedProductTypeId={qDocForm?.selectedProductTypeId}
            />
            <EditQdocFileUpload
              uploadFile={uploadFile}
              setFileData={onSetFileData}
              id={qDocForm.id}
              fileName={qDocForm.fileName}
            />
            <EditQDocComment comment={qDocForm.comment} updateComment={value => updateForm('comment', value)} />
            <ActionRow
              clear={clear}
              toggleSaveModal={beginSave}
              fileName={qDocForm?.fileName}
              uploadFile={qDocForm?.uploadFile}
            />
          </Fragment>
        )}
      </form>
      {showDuplicateModal && (
        <DuplicateModal
          load={id => load(id)}
          qDocs={searchedQDocs}
          toggleDuplicateModal={toggleDuplicateModal}
          serialNumber={qDocForm.serialNumber}
        />
      )}
      {showSaveModal && (
        <SaveQDocModal
          clear={clear}
          editQDoc={editQDoc}
          save={save}
          saveClicked={saveClicked}
          serialNumber={qDocForm.serialNumber}
          showSaveModal={showSaveModal}
          toggleSaveModal={toggleSaveModal}
        />
      )}
    </div>
  );
}
