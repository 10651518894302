import React, { Component } from 'react';

export default class DimensionRangesSelect extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      classes: 'form-control'
    };
  }

  async componentDidMount() {
    this.validate();
  }

  updateSelect = e => {
    e.preventDefault();
    if (!this.validate()) {
      return;
    }
    if (!e.target.selectedOptions) {
      return;
    }
    this.props.onChange(e.target.id, e.target.selectedOptions[0]?.value, this.props.predefinedValues);
  };

  validate() {
    if (!this.inputRef.current || !this.inputRef.current.value) {
      return false;
    }
    if (!this.inputRef.current.checkValidity()) {
      this.setState({
        classes: 'form-control is-invalid'
      });
      return false;
    } else {
      this.setState({
        classes: 'form-control'
      });
      return true;
    }
  }

  renderOption(range, unit) {
    if (!range) {
      return null;
    }
    const measurements = range.measurements.filter(x => x.unit === unit);
    const text = `${range.description}, ${measurements[0]?.value} ${measurements[0]?.unit}`;
    return (
      <option key={range.id} value={range.id}>
        {text}
      </option>
    );
  }

  render() {
    let selectValues = [];
    if (this.props.property && this.props.property.selectValues) {
      selectValues = this.props.property.selectValues;
    }
    if (!selectValues.length) {
      return null;
    }

    return (
      <div className='form-group col-sm-4' style={{ paddingRight: 0 }}>
        <label htmlFor={this.props.property.id}>{this.props.property.description} *</label>
        <select
          defaultValue={this.props.optionId}
          id={this.props.property.id}
          ref={this.inputRef}
          className='form-control'
          onChange={this.updateSelect.bind(this)}
          required={this.props.required}
        >
          {selectValues.length > 1 && (
            <option disabled value=''>
              {this.props.property.description}
            </option>
          )}
          {selectValues.map(data => this.renderOption(data, this.props.unit))}
        </select>
      </div>
    );
  }
}
