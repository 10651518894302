import React, { Component, useMemo } from 'react';
import DimensionRangesDecimalInput from './DimensionRangesDecimalInput.jsx';
import DimensionRangesSelect from './DimensionRangesSelect.jsx';

class DimensionRangesInputs extends Component {
  updateDisable() {
    this.props.disableComponent();
  }

  updateProperty(id, value, hasPredefinedValues) {
    let props = [];
    if (this.props.values.length) {
      props = this.props.values;
    }
    let prop = { validatorId: id, unit: this.props.unit };
    if (hasPredefinedValues) {
      prop.optionId = value;
    } else {
      prop.value = value;
    }
    const index = props.map(e => e.validatorId).indexOf(id);
    if (index > -1 && id) {
      props[index] = prop;
    } else {
      props.push(prop);
    }

    this.props.updatDimensions(props);
  }

  setSelectedDimensionUnit(event) {
    if (!event.target) {
      return;
    }

    this.props.updateUnit(event.target.value);
    this.props.updatDimensions([]);
  }

  render() {
    if (!this.props.ranges.data || !this.props.ranges.data.dimensions || !this.props.ranges.data.dimensions.length) {
      return null;
    }
    return (
      <>
        <div className='form-row col-sm-12 align-items-center'>
          <div className='col-auto'>
            <h5>Dimension data</h5>
          </div>
          <br />
          <div className='col-auto'>
            <div className='form-row col-sm-12'>
              <div className='form-check' title='Check Skip if you want to ignore Dimension data'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.props.isDisabled}
                  onChange={this.updateDisable.bind(this)}
                  id='disableDimensions'
                />
                <label className='form-check-label' htmlFor='disableDimensions'>
                  Skip
                </label>
              </div>
            </div>
          </div>
        </div>
        {!this.props.isDisabled && (
          <div className='form-row col-sm-12'>
            <UnitSelect
              selectedUnit={this.props.unit}
              units={this.props.ranges.data.units}
              onSelectedUnitChanged={this.setSelectedDimensionUnit.bind(this)}
            />
          </div>
        )}
        {!this.props.isDisabled && (
          <Properties
            dimensions={this.props.ranges.data.dimensions}
            values={this.props.values}
            onUpdateProperty={this.updateProperty.bind(this)}
            unit={this.props.unit}
          />
        )}
      </>
    );
  }
}

function UnitSelect({ selectedUnit, units, onSelectedUnitChanged }) {
  return (
    <div className='form-group col-sm-4'>
      <label htmlFor='unit'>Unit *</label>
      <select value={selectedUnit} id='unit' className='form-control' onChange={onSelectedUnitChanged}>
        {units.length > 1 && (
          <option disabled value=''>
            Unit
          </option>
        )}
        {units.map(unit => {
          return (
            <option key={unit} value={unit}>
              {unit}
            </option>
          );
        })}
      </select>
    </div>
  );
}

function Properties({ dimensions, values, unit, onUpdateProperty }) {
  return (
    <div className='form-row col-sm-12'>
      {dimensions.map(dimension => {
        return (
          <Property
            key={dimension.id}
            dimension={dimension}
            values={values}
            unit={unit}
            onUpdateProperty={onUpdateProperty}
          />
        );
      })}
    </div>
  );
}

function Property({ dimension, values, unit, onUpdateProperty }) {
  const [value, optionId] = useMemo(() => {
    let value = null;
    let optionId = '';
    if (values.length) {
      const result = values.filter(x => x.validatorId === dimension.id);
      if (result.length) {
        value = result[0].value;
        optionId = result[0].optionId;
      }
    }
    return [value, optionId];
  }, [values, dimension]);

  if (dimension.selectValues.length || optionId) {
    return (
      <DimensionRangesSelect
        property={dimension}
        onChange={onUpdateProperty}
        optionId={optionId}
        unit={unit}
        predefinedValues={dimension.selectValues.length}
      />
    );
  } else {
    return (
      <DimensionRangesDecimalInput
        property={dimension}
        unit={unit}
        onChange={onUpdateProperty}
        value={value}
        predefinedValues={dimension.selectValues.length}
      />
    );
  }
}

export default DimensionRangesInputs;
