import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import './Admin.css';

export class BaseWrite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveClicked: false,
      name: '',
      invalid: false,
      showError: false,
      filter: '',
      newItem: null,
      selected: ''
    };
  }

  async save(id) {
    if (!this.props.isAdmin()) {
      return;
    }

    this.setState({ saveClicked: true });
    let item = {};
    if (id) {
      item = this.props.items.find(x => x.id === id);
    } else {
      item.name = this.state.name;
      item.invalid = this.state.invalid;
    }

    await this.props.save(item);
    let showError = false;
    if (this.props.saveResult.error) {
      showError = true;
    } else {
      this.props.refreshData();
    }
    this.setState({
      saveClicked: false,
      showError: showError,
      newItem: null,
      name: undefined,
      invalid: false,
      filter: ''
    });
  }

  updateNameOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].name = e.target.value;
      items[index].dirty = true;
    }

    this.props.setItems(items);
  }

  updateInvalidOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].invalid = e.target.checked;
      items[index].dirty = true;
    }
    this.props.setItems(items);
  }

  updateFilter(e) {
    if (!e.target) {
      return;
    }
    const filter = e.target.value;

    const items = this.shallowCopy(
      this.props.allItems.filter(x => x.name.toUpperCase().includes(filter.toUpperCase()))
    );
    this.props.setItems(items);
    this.setState({
      filter: e.target.value
    });
  }

  resetItem(id) {
    const originalItem = this.props.allItems.find(x => x.id === id);
    const items = this.props.items;
    const index = items.findIndex(x => x.id === id);
    items[index] = this.shallowCopy(originalItem);
    items[index].dirty = false;
    this.props.setItems(items);
  }

  shallowCopy(data) {
    return JSON.parse(JSON.stringify(data));
  }

  renderSaveButton(id) {
    return (
      <button
        id={id}
        type='button'
        className='btn btn-primary'
        disabled={this.state.saveClicked || this.props.saveResult.pending}
        onClick={() => this.save(id)}
      >
        Save <i className={this.props.saveResult.pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
      </button>
    );
  }

  renderItem(item) {
    if (!item) {
      return null;
    }
    return (
      <div className='col-sm-12'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-group'>
              <input
                id={item.id}
                className='form-control'
                placeholder='Enter name'
                type='text'
                onChange={e => this.updateNameOnItem(e, item.id)}
                value={item.name}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>{this.renderSaveButton(item.id)}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id={`${item.id}-invalid`}
                checked={item.invalid}
                onChange={e => this.updateInvalidOnItem(e, item.id)}
              />
              <label className='form-check-label' htmlFor={`${item.id}-invalid`}>
                Invalid
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Last changed:</b> {item.created ? new Date(item.created).toLocaleString('sv-SE') : ''}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Last changed by:</b> {item.createdBy}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Id:</b> {item.id}
          </div>
        </div>
      </div>
    );
  }

  addNewItem() {
    const item = { id: '', name: '' };
    this.setState({ newItem: item, name: '', selected: '' });
  }

  renderErrorSaveResult() {
    if (this.state.showError) {
      return this.renderErrorAlert(
        'Unable to save item. If this error reoccurs please contact support.',
        'showError',
        'alert-danger'
      );
    }
    return null;
  }

  renderErrorAlert(message, stateProperty, className) {
    return (
      <div className='row justify-content-center'>
        <div className='col-lg-12 col-sm-12'>
          {this.renderAlert(
            message,
            () =>
              this.setState({
                [stateProperty]: false
              }),
            className
          )}
        </div>
      </div>
    );
  }

  renderAlert(message, onActionFunc, alertClass) {
    return (
      <div className={`alert ${alertClass} alert-not-found alert-dismissible fade show`} role='alert'>
        {message}
        <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={() => onActionFunc()}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
    );
  }

  toggle(e, id) {
    if (this.state.newItem != null) {
      e.stopPropagation();
      this.setState({ selected: undefined });
      return;
    }
    const shouldCollapse = this.state.selected === id;

    this.setState({ selected: shouldCollapse ? '' : id });
  }

  render() {
    if (!this.props.items || !this.props.isAdmin()) {
      return null;
    }
    return (
      <div>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='d-flex justify-content-between'>
              <h3>{this.props.name}</h3>
              <button className='btn btn-secondary btn-sm' onClick={() => this.addNewItem()}>
                Add
              </button>
            </div>
          </div>
          <div className='col-sm-6'>{this.renderErrorSaveResult()}</div>
        </div>
        <br />
        {this.state.newItem && (
          <div>
            <div className='row'>
              <div className='col-sm-12'>
                <Accordion defaultActiveKey='0'>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey='0'>
                      <div className='d-flex justify-content-between'>
                        <div>New</div>
                        <button className='close' onClick={() => this.setState({ newItem: null })}>
                          <span aria-hidden='true'>&times;</span>
                        </button>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-sm-6'>
                              <div className='form-group'>
                                <input
                                  className='form-control'
                                  placeholder='Enter name'
                                  type='text'
                                  onChange={e => this.setState({ name: e.target.value })}
                                  value={this.state.name}
                                />
                              </div>
                            </div>
                            <div className='col-sm-6'>
                              <div className='form-group'>{this.renderSaveButton()}</div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            <br />
          </div>
        )}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                minLength='1'
                placeholder='Filter by name'
                onChange={e => this.updateFilter(e)}
                value={this.state.filter}
              ></input>
            </div>
          </div>
        </div>
        <Accordion activeKey={this.state.selected}>
          {this.props.items.map(item => {
            return (
              <Card key={item.id}>
                <Accordion.Toggle as={Card.Header} eventKey={item.id} onClick={e => this.toggle(e, item.id)}>
                  <div className='d-flex justify-content-between'>
                    <div className={item.invalid ? 'invalid-item' : undefined}>
                      {this.props.allItems.find(x => x.id === item.id).name}
                    </div>
                    {item.dirty && (
                      <button className='close' onClick={() => this.resetItem(item.id)}>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    )}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id}>
                  <Card.Body>
                    <div className='container'>{this.renderItem(item)}</div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </div>
    );
  }
}
