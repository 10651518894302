import React, { Component } from 'react';

class WarningModal extends Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.props.toggle();
  }

  continue() {
    this.props.continue();
    this.props.toggle();
  }

  render() {
    const styles = this.props.show ? { display: 'block' } : { display: 'none' };
    return (
      <div
        id='modal'
        className={`modal fade ${this.props.show ? 'show' : ''}`}
        role='dialog'
        tabIndex='-1'
        style={styles}
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>{this.props.header}</h4>
              <button
                type='button'
                onClick={this.toggleModal.bind(this)}
                className='close'
                data-dismiss='modal'
                aria-label='close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div>{this.props.body}</div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={this.toggleModal.bind(this)}
              >
                Close
              </button>
              {this.props.continueButton && (
                <button className='btn btn-primary' type='button' onClick={this.continue.bind(this)}>
                  {this.props.continueButton}&nbsp;
                  {this.props.pending && (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WarningModal;
