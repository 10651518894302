import React, { useCallback, useMemo } from 'react';
import Dropdown from '../../Dropdown/Dropdown.jsx';

export function EditQDocData({
  suppliers,
  selectedSupplierId,
  updateSupplier,
  products,
  updateProductGroup,
  updateProductType,
  selectedProductGroupId,
  selectedProductTypeId
}) {
  const filteredSupplier = useMemo(() => suppliers?.data?.filter(x => !x.invalid || x.id === selectedSupplierId), [
    suppliers,
    selectedSupplierId
  ]);

  const onUpdateSupplier = useCallback(
    value => {
      updateSupplier(value);
      updateProductGroup('');
    },
    [updateSupplier, updateProductGroup]
  );

  const onUpdateProductGroup = useCallback(
    value => {
      updateProductGroup(value);
      updateProductType('');
    },
    [updateProductGroup, updateProductType]
  );

  const onUpdateProductType = useCallback(
    value => {
      updateProductType(value);
    },
    [updateProductType]
  );

  const productTypes = useMemo(() => {
    const selectedProductGroup = products?.data?.filter(x => x.id === selectedProductGroupId)?.[0] ?? [];
    const types = selectedProductGroup.productTypes ?? [];
    return types;
  }, [products, selectedProductGroupId]);

  return (
    <>
      <div className='form-row col-sm-12'>
        <h5>Data</h5>
      </div>
      <div className='form-row col-sm-12'>
        <Dropdown
          data={filteredSupplier}
          elementName='suppliers'
          entityDescription='Supplier'
          valueProperty='name'
          update={onUpdateSupplier}
          required={true}
          value={selectedSupplierId}
          loading={suppliers?.pending}
          width='col-sm-4'
          error={suppliers?.error}
        />
      </div>
      <div className='form-row col-sm-12'>
        <Dropdown
          data={products?.data}
          elementName='ProductGroup'
          entityDescription='Product Group'
          valueProperty='description'
          update={onUpdateProductGroup}
          required={true}
          value={selectedProductGroupId}
          loading={products?.pending}
          width='col-sm-4'
          error={products?.error}
        />

        <Dropdown
          data={productTypes}
          elementName='ProductType'
          entityDescription='Product Type'
          valueProperty='description'
          update={onUpdateProductType}
          required={true}
          value={selectedProductTypeId}
          loading={products?.pending}
          width='col-sm-4'
        />
      </div>
    </>
  );
}
