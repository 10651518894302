import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export default class SupplierCertificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heatNumber: '',
      supplierCertificates: []
    };
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.certificates &&
      this.props.certificates.data &&
      prevProps.certificates.data !== this.props.certificates.data
    ) {
      this.setState({ pending: true, supplierCertificates: this.props.certificates.data });
    }
  }

  setHeatNumber(e) {
    if (e.target) {
      this.setState({ heatNumber: e.target.value });
    }
  }

  filterCertificates(e) {
    if (!e.target || e.target.value) {
      this.setState({ supplierCertificates: this.props.certificates.data });
    }
    const filterableCertificates = [];
    this.props.certificates.data.forEach(cert => {
      cert.shapeAndDimension = `Supplier Certificate, ${this.parseDate(cert.created)}`;
      if (cert.shape && cert.dimensions) {
        cert.shapeAndDimension = `${cert.shape} ${cert.dimensions} ${cert.created}`;
      }
      filterableCertificates.push(cert);
    });

    var certificates = filterableCertificates.filter(x =>
      x.shapeAndDimension.toUpperCase().includes(e.target.value.toUpperCase())
    );
    this.setState({ supplierCertificates: certificates });
  }

  async searchCertificates() {
    await this.props.search(this.state.heatNumber);
  }

  async onKeyUp(e) {
    if (e.key === 'Enter' && e.target.id === 'searchHeatNumber') {
      if (e.target.value && e.target.value.length) {
        await this.props.search(e.target.value);
      }
    }
  }

  getNonSelectedCertificates(certificates, selectedCertificates) {
    let nonSelectedCertificates = [];
    certificates.forEach(cert => {
      const selected = selectedCertificates.filter(x => x.id === cert.id);
      if (!selected.length) {
        nonSelectedCertificates.push(cert);
      }
    });

    return nonSelectedCertificates;
  }

  renderCertificates(certificates) {
    if (!certificates.length) {
      return null;
    }

    const nonSelectedCertificates = this.getNonSelectedCertificates(certificates, this.props.selectedCertificates);

    return this.renderCertificateRows(nonSelectedCertificates, this.addToSelectedCertificates, 'fa fa-plus');
  }

  renderCertificateRows(certificates, onActionFunc, buttonClass) {
    if (!certificates) {
      return null;
    }
    certificates = this.sortCertificatesByDateDesc(certificates);

    return certificates.map(certificate => {
      let certificateText = `Supplier Certificate, ${this.parseDate(certificate.created)}`;
      if (certificate.shape && certificate.dimensions) {
        certificateText = `${certificate.shape} ${certificate.dimensions}, ${this.parseDate(certificate.created)}`;
      }
      const certificateTitle = `${certificateText}, created: ${this.parseDateTime(certificate.created)}`;
      return (
        <tr key={certificate.id}>
          <td>
            {certificate.heats.map((heat, i) => {
              const heatTitle = `Number: ${heat.heatNumber}, supplier: ${heat.supplier}, created: ${this.parseDateTime(
                heat.created
              )}`;
              return (
                <Link
                  title={heatTitle}
                  key={heat.id}
                  to={'/heat/' + heat.id + '/details'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {`${heat.heatNumber} ${heat.supplier} ${i < certificate.heats.length - 1 ? ',' : ''}`}
                </Link>
              );
            })}
          </td>
          <td>
            <Link
              to={'/supplier-certificate/' + certificate.id + '/details'}
              target='_blank'
              rel='noopener noreferrer'
              title={certificateTitle}
            >
              {certificateText}
            </Link>
          </td>
          <td onClick={() => onActionFunc(certificate, this)}>
            <i className={buttonClass}></i>
          </td>
        </tr>
      );
    });
  }

  renderSelectedCertificates(selectedCertificates) {
    if (!selectedCertificates) {
      return null;
    }

    return this.renderCertificateRows(selectedCertificates, this.removeSelectedCertificate, 'fa fa-minus');
  }

  addToSelectedCertificates(certificate, that) {
    const certificates = that.props.selectedCertificates;
    const index = certificates.map(cert => cert.id).indexOf(certificate.id);
    if (index > -1) {
      return;
    }

    certificates.push(certificate);
    that.props.setCertificates(certificates);
  }

  removeSelectedCertificate(certificate, that) {
    let certificates = that.props.selectedCertificates;
    const index = certificates.map(cert => cert.id).indexOf(certificate.id);
    if (index < 0) {
      return;
    }

    certificates.splice(index, 1);
    that.props.setCertificates(certificates);
  }

  parseDate(created) {
    if (!created) {
      return 'N/A';
    }
    return new Date(created).toLocaleDateString('sv-SE');
  }

  parseDateTime(created) {
    if (!created) {
      return 'N/A';
    }
    return new Date(created).toLocaleString('sv-SE');
  }

  sortCertificatesByDateDesc(certificates) {
    return certificates.sort(function(a, b) {
      return new Date(b.created) - new Date(a.created);
    });
  }

  render() {
    return (
      <Fragment>
        <hr />
        <br />
        <div className='form-row col-sm-12'>
          <h5>Heat with certificates</h5>
        </div>
        <div className='form-row col-sm-8 d-flex align-items-end'>
          <div className='form-group col-sm-5'>
            <label htmlFor='searchHeatNumber'>
              Search heat by number {!this.props.selectedCertificates.length && '*'}
            </label>
            <input
              type='text'
              className='form-control'
              id='searchHeatNumber'
              minLength='1'
              onChange={this.setHeatNumber.bind(this)}
              onKeyUp={this.onKeyUp.bind(this)}
            ></input>
          </div>
          <div className='form-group col-sm-2'>
            <button type='button' className='btn btn-secondary' onClick={this.searchCertificates.bind(this)}>
              <i className={this.props.pending ? 'fas fa-circle-notch fa-spin' : 'fa fa-search'}></i>
            </button>
          </div>
          {this.props.certificates.data && this.props.certificates.data.length && (
            <div className='form-group col-sm-5'>
              <label htmlFor='filterSupplierCertificates'>Filter supplier certificates</label>
              <input
                type='text'
                className='form-control'
                id='filterSupplierCertificates'
                minLength='1'
                placeholder='Shape, dimensions or date'
                onChange={this.filterCertificates.bind(this)}
              ></input>
            </div>
          )}
        </div>
        <div className='form-row col-sm-12'>
          <div className='col-sm-6'>
            <table className='table table-sm table-striped table-hover' id='certificatesTable'>
              <thead>
                <tr>
                  <th colSpan='3'>Matching heats with certificates</th>
                </tr>
              </thead>
              <tbody>{this.renderCertificates(this.state.supplierCertificates)}</tbody>
            </table>
          </div>
          <div className='col-sm-6'>
            <div className='table-responsive-sm'>
              <table className='table table-sm table-striped draggable-table'>
                <thead>
                  <tr>
                    <th colSpan='3'>Selected heats with certificates</th>
                  </tr>
                </thead>
                <tbody>{this.renderSelectedCertificates(this.props.selectedCertificates)}</tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />
        <br />
      </Fragment>
    );
  }
}
