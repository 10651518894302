import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Unauthorized from '../Layout/Unauthoried.jsx';

import fetchAsmeCertificationAsync from '../../store/asmeCertification/fetchAsmeCertification';
import postAsmeCertificationAsync from '../../store/editAsmeCertification/postAsmeCertification';

import { WriteAsmeCertification } from './WriteAsmeCertification.jsx';
import { useAuth } from 'auth/useAuth.js';

export function AsmeCertification() {
  const asmeCertification = useSelector(state => state.asmeCertification);
  const editAsmeCertification = useSelector(state => state.editAsmeCertification);
  const { isAdmin } = useAuth();
  const dispatch = useDispatch();

  const [certificate, setCertificate] = useState(undefined);

  const fetchData = useCallback(async () => {
    await dispatch(fetchAsmeCertificationAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!asmeCertification?.pending && !!asmeCertification?.data) {
      const { id, certifications } = asmeCertification.data;
      if (id && certifications) {
        setCertificate({ id, certifications });
      }
    }
  }, [asmeCertification]);

  const setItems = useCallback(
    certifications => {
      setCertificate({ ...certificate, certifications });
    },
    [certificate]
  );

  const save = useCallback(async () => {
    await dispatch(postAsmeCertificationAsync(certificate));
    await fetchData();
  }, [dispatch, certificate, fetchData]);

  if (!isAdmin()) {
    return <Unauthorized />;
  }
  return (
    <WriteAsmeCertification
      name='ASME Certification'
      items={certificate?.certifications}
      save={save}
      saveResult={editAsmeCertification}
      refreshData={fetchData}
      setItems={setItems}
      isAdmin={isAdmin}
    />
  );
}
