import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import Download from '../Download/Download';
import { PaginatedResult } from './PaginatedResult.jsx';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';

import './SearchCertificatesResult.css';
import { useAuth } from 'auth/useAuth';

export function SearchSupplierCertificates({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchSupplierCertificates);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  if (!shouldRender) {
    return null;
  }
  const totalCount = hasElevatedRole()
    ? data?.totalCount
    : data?.totalCount > data?.results.length && data?.totalCount > 15
    ? `${15}+`
    : data?.results.length;

  return (
    <Accordion className='mt-4'>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey='0'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='btn btn-link'>Supplier Certificates</div>
            {error && <div style={{ color: 'red' }}>{error.message}</div>}
            <div>{pending ? <i className='fas fa-circle-notch fa-spin' /> : totalCount}</div>
          </div>
        </Accordion.Toggle>
        {data?.results?.length > 0 && (
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              {data?.results?.map((certificate, index) => (
                <CertificateRow key={`${certificate.id}_${index}`} certificate={certificate} />
              ))}
              <br />
              <PaginatedResult totalCount={data.totalCount} pageCount={pageCount} search={search} pending={pending} />
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
}

function CertificateRow({ certificate }) {
  const supplierCertificate = certificate?.supplierCertificate;

  const { hasElevatedRole } = useAuth();

  return (
    supplierCertificate && (
      <Accordion>
        <Card className={supplierCertificate.invalid ? 'invalid-card' : undefined}>
          <Accordion.Toggle as={Card.Header} eventKey='0'>
            <div className='row'>
              <div className='col-lg-3 col-md-3'>
                <b>Heats</b>
                <ul className='list-unstyled mb-md-0'>
                  {certificate.heats.map((heat, index) => (
                    <li key={`supplier_${heat.id}_${index}`}>
                      <SearchResult type='heat' id={heat.id} description={`${heat.heatNumber} ${heat.supplier}`} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className='col-lg-4 col-md-4'>
                <b>Supplier Certificate</b>
                <ul className='list-unstyled mb-md-0'>
                  <li>
                    <SearchResult
                      type='supplier-certificate'
                      id={supplierCertificate.id}
                      description={`${supplierCertificate.shape.trim()} ${supplierCertificate.dimension.trim() ||
                        'N/A'}`}
                    />
                  </li>
                </ul>
              </div>
              <div className='col-lg-4 col-md-4'>
                <b>Download</b>
                <ul className='list-unstyled'>
                  <li>
                    <Download
                      fileName={supplierCertificate.id}
                      identifier={supplierCertificate.id}
                      documentType='supplier-certificate'
                      alt={'Download Supplier Certificate file'}
                      onClick={stopPropagation}
                    />
                    {supplierCertificate.invalid && <i className='fa fa-exclamation m-1 invalid-certificate' />}
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Toggle>
          {hasElevatedRole() ? (
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <div className='container'>
                  <SupplierCertificate certificate={certificate} />
                </div>
              </Card.Body>
            </Accordion.Collapse>
          ) : null}
        </Card>
      </Accordion>
    )
  );
}

function SearchResult({ type, id, description, invalid }) {
  return (
    <Link
      className={invalid ? 'invalid-certificate' : undefined}
      key={id}
      to={`/${type}/${id}/details`}
      target='_blank'
      rel='noopener noreferrer'
      onClick={stopPropagation}
      title={invalid ? 'The certificate is invalid' : undefined}
    >
      {`${description}`} {invalid && <i className='fa fa-exclamation m-1' />}
    </Link>
  );
}

function SupplierCertificate({ certificate }) {
  const supplierCertificate = certificate.supplierCertificate;
  return (
    <Fragment key={supplierCertificate.id}>
      <div className='row'>
        <b>
          Supplier certificate:{' '}
          <SearchResult
            type='supplier-certificate'
            id={supplierCertificate.id}
            description={`${supplierCertificate.shape.trim()} ${supplierCertificate.dimension.trim() || 'N/A'}`}
            invalid={supplierCertificate.invalid}
          />
        </b>
      </div>
      <div className='row'>
        <b>Lot numbers:</b>
        <span className='ml-1 text-break'>{certificate.lotNumber}</span>
      </div>
      <div className='row'>
        <b>PO numbers:</b>
        <span className='ml-1 text-break'>{certificate.purchaseOrderNumbers.join(', ')}</span>
      </div>
      <br />
    </Fragment>
  );
}
