import React, { useCallback } from 'react';

export function ActionRow({ clear, toggleSaveModal, uploadFile, fileName }) {
  const onClear = useCallback(() => clear(), [clear]);
  const onToggleSaveModal = useCallback(() => toggleSaveModal(), [toggleSaveModal]);

  return (
    <div className='form-row col-sm-12'>
      <div className='form-group col-auto'>
        <button
          type='button'
          title='Note that this button will reset the entered data...'
          className='btn btn-secondary'
          onClick={onClear}
        >
          Clear
        </button>
      </div>
      <div className='form-group col-auto'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={onToggleSaveModal}
          disabled={uploadFile?.pending || fileName === null}
        >
          Save
        </button>
      </div>
    </div>
  );
}
