import React, { useCallback } from 'react';

export function EditQDocComment({ comment, updateComment }) {
  const onCommentChange = useCallback(event => updateComment(event?.target?.value), [updateComment]);
  return (
    <div className='form-row col-sm-12'>
      <div className='form-group col-sm-8'>
        <label htmlFor='comment'>Comment</label>
        <textarea className='form-control' rows={3} id='comment' onChange={onCommentChange} value={comment} />
      </div>
    </div>
  );
}
