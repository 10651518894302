import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditCertificateBase from '../CertificateBase/EditCertificateBase.jsx';
import CertificateSource from '../CertificateSource/CertificateSource.jsx';
import postAdHocMtrAsync from '../../store/createAdHocMtr/postMtr';
import { useHistory, useLocation } from 'react-router';
import fetchSurfacesAsync from 'store/surfaces/fetchSurfaces.js';
import { useApi } from 'api/useApi.js';
import { postAdHocMtrClear } from 'store/createAdHocMtr/actions.js';
import { useAuth } from 'auth/useAuth.js';

const TYPE = 'ADHOCMTR';
const DESCRIPTION = 'AD HOC MTR';

export function CreateAdHocMtr() {
  const [certificateData, setCertificateData] = useState(undefined);
  const [pdfHtmlRendered, setPdfHtmlRendered] = useState(undefined);
  const [pendingSave, setPendingSave] = useState(undefined);
  const [pdfHtmlData, setPdfHtmlData] = useState(undefined);
  const [savedCertificate, setSavedCertificate] = useState(undefined);

  const surfaces = useSelector(state => state.surfaces);
  const adHocMtr = useSelector(state => state.adHocMtr);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { getJson } = useApi();
  const { isAsmeSupplier } = useAuth();

  const clearState = useCallback(() => {
    setPendingSave(false);
    setPdfHtmlData(undefined);
    setPdfHtmlRendered(false);
    setSavedCertificate(undefined);
  }, []);

  useEffect(() => {
    return () => {
      clearState();
      dispatch(postAdHocMtrClear());
    };
  }, [dispatch, clearState]);

  useEffect(() => {
    dispatch(fetchSurfacesAsync(TYPE));
  }, [dispatch]);

  const fetchBasicSupplierCertificatesAsync = useCallback(
    async heatId => {
      return await getJson(`supplier-certificate/basic-information/${encodeURIComponent(heatId)}`);
    },
    [getJson]
  );

  const generateItems = useCallback(items => {
    const itemsWithIds = items.filter(x => x.documentId !== undefined);
    if (itemsWithIds.length === items.length) {
      return items;
    }
    const itemsWithoutDocumentIds = items.filter(x => x.documentId === undefined);
    itemsWithoutDocumentIds.forEach((single, index) => {
      let item = itemsWithoutDocumentIds[index];
      item.documentId = 'ADHOC MTR';
      itemsWithIds.push(item);
    });

    return itemsWithIds;
  }, []);

  // Demanded by EditCertificateBase
  const loadCertificate = async () => {};

  const resetUrl = useCallback(() => {
    history.replace('/adhoc-mtr');
  }, [history]);

  useEffect(() => {
    if (adHocMtr?.pending && !adHocMtr?.data) return;

    if (adHocMtr?.data?.size) {
      // IE hack
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let fileName = 'ADHOC MTR.pdf';
        window.navigator.msSaveBlob(adHocMtr.data, fileName);
      } else {
        var element = document.createElement('a');
        element.href = window.URL.createObjectURL(adHocMtr.data);
        element.setAttribute('download', 'ADHOC MTR.pdf');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      setPendingSave(false);
      setPdfHtmlData(undefined);
      setPdfHtmlRendered(false);
      setSavedCertificate(adHocMtr);
    }
  }, [adHocMtr, history]);

  const createAdHocMtr = useCallback(async () => {
    setPendingSave(true);
    certificateData.items.forEach(item => {
      var element = document.getElementById(item.documentId);
      item.html = element.innerHTML;
    }, this);

    dispatch(postAdHocMtrAsync(certificateData));
  }, [dispatch, certificateData]);

  const onSetCertificateData = useCallback(data => {
    setCertificateData(data);
  }, []);

  const onPdfHtmlRendered = useCallback(() => {
    setPdfHtmlRendered(!pdfHtmlRendered);
  }, [pdfHtmlRendered]);

  const startRenderPdfHtml = useCallback((certificateData, selectedCertificates, selectedStandard) => {
    setPdfHtmlData({
      certificateData: certificateData,
      selectedCertificates: selectedCertificates,
      selectedStandard: selectedStandard
    });
  }, []);

  return (
    <>
      <EditCertificateBase
        save={createAdHocMtr}
        savedCertificate={savedCertificate}
        pendingSave={pendingSave}
        generateItemsWithDocumentIds={generateItems}
        setCertificateData={onSetCertificateData}
        certificateData={certificateData}
        loadCertificate={loadCertificate}
        type={TYPE}
        description={DESCRIPTION}
        id={undefined}
        renderPdfHtml={startRenderPdfHtml}
        pdfHtmlRendered={onPdfHtmlRendered}
        resetUrl={resetUrl}
        location={location}
        history={history}
        surfaces={surfaces}
        fetchBasicSupplierCertificatesAsync={fetchBasicSupplierCertificatesAsync}
        claerState={clearState}
        isAsmeSupplier={isAsmeSupplier}
      />
      {pdfHtmlData && (
        <div style={{ display: 'none' }}>
          <CertificateSource
            data={pdfHtmlData.certificateData}
            supplierCertificates={pdfHtmlData.selectedCertificates}
            standard={pdfHtmlData.selectedStandard}
            updateComplete={setPdfHtmlRendered}
            type={TYPE}
          />
        </div>
      )}
    </>
  );
}
