import React, { Component } from 'react';

class SaveModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveClicked: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.save = this.save.bind(this);
  }

  createAnother() {
    this.props.createAnother();
    this.props.toggle();

    this.setState({
      saveClicked: false
    });
  }

  toggleModal() {
    this.setState({
      saveClicked: false
    });
    this.props.toggle();
  }

  async save() {
    this.setState({
      saveClicked: true
    });
    await this.props.save();
  }

  render() {
    const styles = this.props.show ? { display: 'block' } : { display: 'none' };
    const modalClasses = this.props.large ? 'modal-dialog modal-lg' : 'modal-dialog';
    return (
      <div
        id='modal'
        className={`modal fade ${this.props.show ? 'show' : ''}`}
        role='dialog'
        tabIndex='-1'
        style={styles}
        aria-hidden='true'
      >
        <div className={modalClasses} role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>{this.props.header}</h4>
              <button
                type='button'
                onClick={this.toggleModal}
                className='close'
                data-dismiss='modal'
                aria-label='close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div>{this.props.body}</div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary mr-auto'
                data-dismiss='modal'
                onClick={this.createAnother.bind(this)}
                title='Note that when pressing this everything will be cleared, make sure to save first...'
                style={
                  this.state.saveClicked && !this.props.pending && this.props.data
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              >
                Create another
              </button>
              <button
                className='btn btn-primary'
                type='button'
                onClick={this.save}
                disabled={this.state.saveClicked || this.props.pending}
              >
                Save&nbsp;
                {this.props.pending && (
                  <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SaveModal;
